@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

/* 
    Alert
    - We don't override Bootstrap's entire alert system for this one component
*/

.header-alert {
    position: relative;
    padding: map-get(bootstrap.$spacers, 1)*0.5 map-get(bootstrap.$spacers, 1);
    color: colors.$white;
    /*background-color: functions.brand-color('alert');*/
    text-align: center;

    a, .h1, .h2, .h3, .h4, .h5, .h6 {
        display: inline-block;
        margin: 0;
        padding: map-get(bootstrap.$spacers, 1)*0.5 map-get(bootstrap.$spacers, 1);
        color: colors.$white;
        /*background-color: functions.brand-color('alert');*/
        border: solid bootstrap.$border-width transparent;
    }

    a:hover,
    a:focus {
        border-color: colors.$white;
    }

    .rich-text {
        margin-bottom: map-get(bootstrap.$spacers, 1)*0.5;
        line-height: bootstrap.$line-height-sm;
    }
}

/*Dark blue brand */
.background-0067B1 {
    background-color: #0067B1;
}

/*Dark blue accent */
.background-072C8A {
    background-color: #072C8A;
}

/*Red messages*/
.background-DB2E2E {
    background-color: #DB2E2E;
}



