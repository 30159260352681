@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'base/layout';
@use 'vendors/bootstrap';    


/*
    Journey Companion Menu
*/ 


// Mobile

@include bootstrap.media-breakpoint-down(sm) {

    #journey-companion-dropdown {
        color: functions.brand-color();
        background-color: functions.tint(functions.brand-color('subtle'), 95);
    }

    #nav-journey-companion {


        .container, // un-inherit navbar flex
        .nav-content {
            display: block; 
        }

        .dropdown-menu {
            text-align: center;
        }

        .header > .rich-text {
            margin: map-get(bootstrap.$spacers, 1) 0;
            line-height: bootstrap.$line-height-sm;
        }

        // Convert to simpler horizontal cards with no button in mobile (use JS)

            .card {
                flex-direction: row;
                align-items: center;
                max-width: 21.5rem;
                margin: 0 auto bootstrap.$card-spacer-y*0.5 auto;
                padding: 0;
                box-shadow: none;
                cursor: pointer;
                border-width: 0;
            }

            .card-layout-wrap {
                display: flex;
                flex: 1 1 auto;
            }

            .card-img-wrapper {
                flex-shrink: 0;
                width: 6rem;
                padding: 0;
            }

            .card-body {
                padding: map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 2);
                text-align: left;
            }

            .card-footer {
                display: none;
            }

            .card-title {
                margin-bottom: map-get(bootstrap.$spacers, 1);
                text-decoration: bootstrap.$link-decoration;
            }

            .card-text {
                font-size: bootstrap.$font-size-base;
                line-height: bootstrap.$line-height-sm;
                margin-bottom: 0;
            }
    }

} // end: Mobile


// Tablet+
@include bootstrap.media-breakpoint-up(md){

    // Journey Companion, Emergency, and Support SK top level buttons should look about the same
    #journey-companion-dropdown {

        @include mixins.header-rounded-button();
        border-width: bootstrap.$border-width 0 bootstrap.$border-width bootstrap.$border-width;
        @include bootstrap.border-left-radius(bootstrap.$btn-border-radius);
        font-size: bootstrap.$font-size-sm;
        white-space: nowrap;
        
        margin-left: map-get(bootstrap.$spacers, 1); // see #search-jc-wrap

        &:hover,
        &:focus,
        &[aria-expanded="true"] {
            border-color: functions.brand-color();
        }

        .nav-icon {
            margin-right: bootstrap.$nav-link-padding-x*0.5;
        }
    }    

    #nav-journey-companion {
        position: relative;

        .container {
            display: block; // un-inherit navbar flex
        }

        & > .dropdown-menu {
            width: 100vw;
            max-width: variables.$site-max-width;
            text-align: center;
            margin-top: .5rem;
        }

        .nav-content {
            flex-basis: 100%;
        }

        .header > .rich-text,
        .card-collection .cards > .col {
            margin-bottom: 0;
        }

        // Standard cards

        .card,
        .card-layout-wrap,
        .card-body {
            height: 100%;
        }

        .card {
            box-shadow: none;
            border-width: 0;
            margin: 0;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .card-title {
            color: functions.brand-color();
        }

        .card-text {
            font-size: bootstrap.$font-size-base;
        }

        // We override the button styles as the JC cards use
        // existing blocks rather than use .btn-brand/.btn-outline-brand...
        .btn-primary {
            @include mixins.btn-brand();
        }

        .btn-outline-primary {
            @include mixins.btn-outline-brand();
        }
    }

}