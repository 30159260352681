@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'vendors/bootstrap';

$card-content-spacer: bootstrap.$card-spacer-y*0.5;

.card-standard {

	.card-body {
		//position: relative; // For .stretched-link
		padding-bottom: $card-content-spacer;
		width: 100%;
	}

	.card-layout-wrap > :last-child {
		padding-bottom: bootstrap.$card-spacer-y;
	}

	.card-body + .card-footer {
		padding-bottom: $card-content-spacer;
	}

	.card-img-wrapper {
		position: relative;
		flex: 0 0 auto;
		/*height: 20em;
		overflow: hidden;*/

		.badge {
			position: absolute;
			bottom: bootstrap.$card-spacer-y*0.5;
			left: bootstrap.$card-spacer-x;
		}
	}

	.card-layout-wrap {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
	}

	.card-text {
		font-size: bootstrap.$font-size-sm;
	}

	.btn-toolbar-social-small {
		justify-content: space-around;
		flex-wrap: nowrap;

		@include bootstrap.media-breakpoint-up(md) {
			justify-content: flex-start;
		}
	}

	.card-footer {
		width: 100%;
		z-index: 10; //This is so the footer remains clicable when a .stretch-link is present within the card.
	}

	.card-footer .btn + .btn-toolbar-social-small {
		margin-top: $card-content-spacer;
	}
}


/*@include bootstrap.media-breakpoint-down(md) {

	.card-standard .card-img-wrapper {		
		height: 16em;
	}
}*/

@include bootstrap.media-breakpoint-down(md) {
	.card-standard.hide-img-mobile {
		.card-img-wrapper {
			display: none;
		}
	}
}


/* Horizontal variation */

.card-standard.card-horizontal {
	flex-direction: row;

	.card-img-wrapper {
		flex: 0 0 30%;

		& > img {
			@include mixins.object-fit(cover);
			height: 100%;
		}
	}

	.card-img {
		@include bootstrap.border-top-radius(bootstrap.$card-inner-border-radius);
		@include bootstrap.border-bottom-radius(0);
	}

	.card-layout-wrap {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
	}

	.card-footer {
		flex: 1 1 100%;
		align-self: flex-end;
	}

	.card-img-left .card-img {
		@include bootstrap.border-right-radius(0);
		@include bootstrap.border-left-radius(bootstrap.$card-inner-border-radius);
	}

	.card-img-right .card-img {
		@include bootstrap.border-right-radius(bootstrap.$card-inner-border-radius);
		@include bootstrap.border-left-radius(0);
	}

	&.img-right.img-top-in-mobile .card-img-left {
		display: none;
	}
}

@include bootstrap.media-breakpoint-down(sm) {
	.card-standard.card-horizontal.img-top-in-mobile {
		flex-direction: column;

		&.img-right .card-img-left {
			display: block;
		}

		.card-img-right {
			display: none;
		}

		.card-img-wrapper {
			flex: 1 1 auto;

			.card-img {
				@include bootstrap.border-top-radius(bootstrap.$card-inner-border-radius);
				@include bootstrap.border-bottom-radius(0);
			}
		}
	}
}

@include bootstrap.media-breakpoint-down(md) {
	.card-standard.card-horizontal.hide-img-mobile {
		.card-layout-wrap {
			flex: 0 0 100%;
		}
	}
}
