@use 'abstracts/colors';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

.card-collection {

    ul.cards {
        padding-left: 0;
    }

    ol.cards {
        padding-left: 0;
    }

    .cards > .col {
        margin-bottom: bootstrap.$grid-gutter-width*0.5;

        @include bootstrap.media-breakpoint-up(md) {
            margin-bottom: bootstrap.$grid-gutter-width;
        }

        .card {
            height: 100%;
        }
    }

    @include bootstrap.media-breakpoint-down(sm) {
        .cards > .col:last-of-type {
            margin-bottom: 0;
        }

        .cards + .footer {
            margin-top: bootstrap.$spacer;
        }
    }
}