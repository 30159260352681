@use 'abstracts/colors';
@use 'vendors/bootstrap';

.FormSelection {
    select {
        @extend .custom-select;
        
        display: block;
        
        &:focus {
            box-shadow: none;
        }

        &[multiple] > option {
            padding: .25rem;
        }

    }
}