@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


/* 
    Styles for the parent .rich-text blocks/sections
*/

    .rich-text {
        margin-bottom: bootstrap.$spacer;

        ul, ol, table, figure {
            margin-bottom: bootstrap.$paragraph-margin-bottom;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    .card + .rich-text {
        margin-top: bootstrap.$spacer;
    }


/* 
    Extra styles for .rich-text content
*/


    // Text Styles

    .text-brand { 
        color: functions.brand-color(); 
    }

    .body-small { 
        font-size: bootstrap.$font-size-sm; 
    }

    .underline {
        text-decoration: underline;
    }