@use "abstracts/colors";
@use "abstracts/functions";
@use "abstracts/mixins";
@use "abstracts/variables";
@use "vendors/bootstrap";


.dropdown.dropdown-tabs {
    display: flex;
    position: relative;
    margin-bottom: bootstrap.$spacer*0.5;

    .dropdown-menu.hide {
        display: none;
    }
    .dropdown-menu.show {
        width: 100%;
    }

    .dropdown-toggle {
        position: relative;
        width: 100%;
        max-width: none;
        text-align: left;
        font-size: bootstrap.$font-size-base;
        @include bootstrap.border-radius(bootstrap.$border-radius-sm);

        @include bootstrap.media-breakpoint-up(lg){
            // Since we're mashing bootstrap components, we have to override the JS unfortunately
            display: none !important;
        }

        &::after {
            position: absolute;
            top: 42.5%;
            right: bootstrap.$btn-padding-x;
        }

        @include mixins.collapse-icon($right: bootstrap.$dropdown-item-padding-x*0.5);
    }

    .nav-tabs .dropdown-item {
        padding-right: bootstrap.$dropdown-item-padding-x*0.5;
        padding-left: bootstrap.$dropdown-item-padding-x*0.5;
    }

    .active {
        text-decoration: bootstrap.$link-decoration;
    }
}


.dropdown-tabs > .nav-tabs.dropdown-menu {

    display: none;

    &.show {
        display: block;
    }

    @include bootstrap.media-breakpoint-up(lg){
        flex-wrap: initial;
        position: static;
        float: none;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        border: bootstrap.$btn-border-width solid functions.theme-color-tint('primary','30');
        @include bootstrap.border-radius(bootstrap.$nav-tabs-border-radius);
        padding: .125rem;
        
        .dropdown-item {
            flex: 1 1 auto;
            padding: bootstrap.$dropdown-item-padding-y bootstrap.$dropdown-item-padding-x;
            text-align: center;
            line-height: bootstrap.$line-height-sm;
            white-space: normal;
            border: calc(bootstrap.$border-width / 2) solid colors.$white;
            @include bootstrap.border-radius(bootstrap.$nav-tabs-border-radius);
            @include bootstrap.transition(bootstrap.$btn-transition);

            &:focus,
            &.focus {
                outline: 0;
                border-color: functions.theme-color-tint('primary','60');
                background-color: colors.$white;
            }

            &.active:focus {
                background-color: functions.theme-color-tint('primary','15');
            }
        }

        .dropdown-item + .dropdown-item {
            margin-left: .25rem;
        }   
    }
}


/* Extra styles for non-JavaScript fall-back */

.no-js {

    .dropdown-tabs {
         display: none;
    }

    .tab-content > .tab-pane {
        display: block;
        opacity: initial;
    }
}