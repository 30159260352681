@use "abstracts/colors";


/*
    Global non-Bootstrap Variables
*/

$site-min-width:            20rem;
$site-max-width:            120rem;

$external-link-icon-size:   .875em;

$btn-circle-padding:        .625em; // Should be ~== bootstrap.$btn-padding-y
$btn-icon-size:             1em;
$btn-icon-size-lg:          1.25em;
$btn-icon-size-xl:          2em;

$line-height-md:            1.5;

$dropdown-chevron-size:     .625rem;

// Used for global spacing between components in _layout.scss
$component-margin:          3rem;
$component-margin-lg:       5rem;

// SVG Icons

$svg-color-placeholder: "{{color}}";
$svg-icons: ( // IMPORTANT: ie11 requires single quotes!

    // From bootstrap/variables
    'bootstrap-navbar-toggler': "<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='{{color}}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>",

    // images/icons/interface/fixed/quote.svg
    'quote': "<svg width='348' height='229' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='{{color}}' d='M166.669 82.87C166.669 37.206 129.275 0 83.334 0 37.414 0 0 37.185 0 82.87c0 45.663 37.394 82.869 83.334 82.869 9.95 0 19.881-1.876 28.888-5.186-1.887 20.707-8.045 41.893-18.457 61.684-.943 1.416-.943 3.291-.48 4.708.48.937 1.423 1.875 2.367 1.875 1.424.479 2.848 0 4.734-1.416 19.881-16.956 36.932-39.08 48.286-63.557 10.412-22.602 16.57-46.601 17.513-72.037.481-2.814.484-6.127.484-8.94zM348 82.87C348 37.206 310.606 0 264.666 0c-45.92 0-83.335 37.185-83.335 82.87 0 45.663 37.394 82.869 83.335 82.869 9.949 0 19.88-1.876 28.887-5.186-1.886 20.707-8.045 41.893-18.457 61.684-.943 1.416-.943 3.291-.48 4.708.48.937 1.424 1.875 2.367 1.875 1.424.479 2.848 0 4.734-1.416 19.881-16.956 36.932-39.08 48.286-63.557 10.412-22.602 16.57-46.601 17.513-72.037.481-2.814.484-6.127.484-8.94z'/></svg>",

    // images/icons/interface/themeable/external-link.svg
    'external-link': "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.1 15.1'><path d='M5.6 4.763H1v9.4h9.3v-5.1' fill='none' stroke='{{color}}' stroke-width='1.25'/><path fill='none' stroke='{{color}}' stroke-width='1.25' stroke-linecap='square' d='M5.215 1h8.9M14.115 9.9V1'/><path fill='none' stroke='{{color}}' stroke-width='1.25' d='M4.315 10.8l9.8-9.8'/></svg>",

);
