@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'base/layout';
@use 'vendors/bootstrap';

@include bootstrap.media-breakpoint-down(sm) {

    // Primary Nav - Second level dropdowns (i.e. under Assistance)
    #nav-primary {

        // Remove container padding
        .dropdown-layout-wrap {
            padding: 0;
        }

        // Add some border to seperate from navbar
        .dropdown.show > .dropdown-toggle {
            border-top: bootstrap.$border-width solid functions.tint(functions.brand-color(), 60);
        }

        .nav-link {
            background-color: colors.$white;
        }

        .nav-pages-level-2 > .nav-column-wrap > .navbar-nav > .nav-item > .nav-link {
            color: colors.$white;
            background-color: functions.brand-color();

            &:focus::after,
            &:hover::after,
            &::after {
                border-color: colors.$white;
            }

            &.external-link:after {
                background-image: functions.get-svg('external-link', colors.$white);
            }

        }
        
        .dropdown-submenu > .dropdown-menu .nav-link {
            background-color: functions.tint(functions.brand-color(), 95);
        }

    }
}

@include bootstrap.media-breakpoint-up(md){

    #nav-primary {
        position: relative;
        padding: bootstrap.$btn-padding-y*0.5 0;

        .nav-link {
            background-color: colors.$white;
        }

        // First-level 
        & > .navbar-nav {
            align-items: center;

            & > .nav-item {
                margin-bottom: 0;
                position: static;

                &  > .nav-link {
                    border: bootstrap.$border-width solid colors.$white;

                    &:hover,
                    &:focus {
                        color: functions.brand-color('dark');
                        border-bottom-color: functions.brand-color();
                    }
                }

                & > .dropdown-menu {
                    width: 100%;
                    max-width: variables.$site-max-width;

                }
            }
        }

        .dropdown-layout-wrap {
            max-width: map-get(bootstrap.$container-max-widths, 'xl');
            
            .nav-link {
                border: none;

                &:focus {
                    background-color: functions.tint(functions.brand-color(), 85);
                    text-decoration: bootstrap.$link-hover-decoration;
                }
            }
        }



        // Submenus
        .dropdown-layout-wrap > .nav-pages {
            display: flex;
            flex-wrap: wrap;
        }
        .dropdown-layout-wrap > .nav-pages > .dropdown-title {
            flex: 1 1 auto;
        }
        .dropdown-layout-wrap > .nav-pages > .dropdown-desc {
            flex: 1 1 100%;
        }
        .dropdown-layout-wrap > .nav-pages > .dropdown-submenu-close {
            flex: 0 0 auto;
            cursor: pointer;
            align-self: flex-start;

            @include mixins.btn-icon(
                $fg: functions.brand-color(), 
                $bg: functions.tint(functions.brand-color('accent'), 85),
                $hover-fg: functions.brand-color(), 
                $hover-bg: functions.tint(functions.brand-color(), 85),
                $active-fg: colors.$white, 
                $active-bg: functions.brand-color()
            );

            &:focus {
                border-color: functions.brand-color();
            }
        }
    }

    #navbar-brand-desktop > .btn {
        padding: bootstrap.$btn-padding-y*0.5 bootstrap.$btn-padding-x*0.5;
        @include bootstrap.hover-focus-active(){ // see also #navbar-brand-tablet
            border-color: functions.brand-color();
            background-color: colors.$white;
        }
    }

}


@include bootstrap.media-breakpoint-between(md, lg) {

    #nav-primary {

        & > .navbar-nav {
            justify-content: space-around;
        }
    }

}


@include bootstrap.media-breakpoint-up(xl) {

    #nav-primary {
        position: static;

        .dropdown-menu {
            margin-top: -3rem; // In order to be full width, #nav-primary is not relative...
        }

        & > .navbar-nav {
            justify-content: space-evenly;
            margin: 0 auto;
            max-width: map-get(bootstrap.$container-max-widths, 'xl');

            & > .nav-item {
                flex: 1 1 0%;
                justify-content: center;
            }

            & > #navbar-brand-desktop {
                flex: 0 0 auto;
            }
        }

        .dropdown-layout-wrap {
            @include bootstrap.make-container();
            width: 65vw;
            max-width: variables.$site-max-width;
            min-width: map-get(bootstrap.$container-max-widths, 'xl');
        }
    }

    @include mixins.ie11(){

        #nav-primary > .navbar-nav {
            justify-content: space-around;
        }

        #nav-primary > .navbar-nav > .nav-item {
            flex: 0 0 auto;
        }
    }

}