@use 'abstracts/colors';
@use 'vendors/bootstrap';

/*.EPiServerForms {
    &.ValidationSuccess, &.ValidationFail {
        @extend .was-validated;
    }
}*/

.Form__Element {
    @extend .form-group;

    position: relative;
    max-width: 100%;


    &.ValidationSuccess {
        & .FormTextbox__Input, & select {
            @extend .is-valid;
        }
    }


    &.ValidationFail {
        & .FormTextbox__Input, & select {
            @extend .is-invalid;
        }


        & .Form__Element__ValidationError {
            @extend .alert;
            @extend .alert-danger;

            display: block;
        }
    }
}

.Form__Element,
.FormCaptcha__Refresh,
.FormCaptcha__Image,
.Form__Element__Caption,
.FormFileUpload .FormFileUpload__Input,
.FormSelection > select,
.FormTextbox__Input {
    margin-bottom: map-get(bootstrap.$spacers, 1);
}


.ValidationRequired label:after { 
    content: " (required)";
}

