@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

.content-page-image-wrapping {
    position: relative;
    display: inline;
    padding-bottom: 1em;    
}
.float-left {
    float: left;
    padding-right: 1em;
}

.float-right {
    float: right;
    padding-left: 1em;
}

@include bootstrap.media-breakpoint-only(xs) {

    .float-left {
        float: inherit;
        padding-right: 0em;
    }

    .float-right {
        float: inherit;
        padding-left: 0em;
    }
}
