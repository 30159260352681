@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'base/layout';
@use 'vendors/bootstrap';


/*
    Support SickKids dropdown
*/


// Mobile
@include bootstrap.media-breakpoint-down(sm) {

    #support-sickkids-dropdown {
        color: functions.brand-color();
        background-color: functions.tint(functions.brand-color('subtle'), 95);
    }

}


// Tablet+
@include bootstrap.media-breakpoint-up(md){

    // Journey Companion, Emergency, and Support SK top level buttons should look about the same
    #support-sickkids-dropdown {
        @include mixins.header-rounded-button(
            $color: colors.$white, 
            $bg-color: bootstrap.theme-color()
        );
        font-size: bootstrap.$font-size-sm; // match secondary nav
        border-width: 0;
        @include bootstrap.border-left-radius(bootstrap.$btn-border-radius);

        &[aria-expanded="true"] {
            text-decoration: bootstrap.$link-decoration;
        }

        .nav-icon {
            margin-right: bootstrap.$nav-link-padding-x;
        }
    }

    #nav-support-sk {
        background-color: functions.tint(bootstrap.theme-color(), 95);
        align-self: stretch;
        display: flex;

        & > .dropdown-menu {
            width: 40rem;
        }

        .nav-pages {
            flex-basis: 60%;
        }

        .nav-content {
            flex-basis: 40%;
            display: inline-flex;
            justify-content: center;
        }

        .dropdown-menu .nav-link {
            flex: 1 1 0%;
        }
    }
}


@include bootstrap.media-breakpoint-up(xl) {
    
    // This is only for centering secondary nav
    #nav-support-sk {
        flex: 1 1 0%;
        justify-content: flex-end;
    }

 }