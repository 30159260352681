@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

$quote-padding: 20%;

.quote {
    display: block;
    padding-right: $quote-padding*0.25;
    padding-left: $quote-padding*0.25;

    @include bootstrap.media-breakpoint-up(md) {
        padding: 0 $quote-padding*0.25;
    }

    @include bootstrap.media-breakpoint-up(lg) {
        padding: 0 $quote-padding*0.25;
        // Note: .quote-text is padded below as well
    }
}

.quote > .blockquote {
    display: block;
    padding-top: bootstrap.$spacer;
    color: bootstrap.theme-color('dark');
    font-style: italic;
    line-height: bootstrap.$line-height-base*.8;

    @include bootstrap.media-breakpoint-down(lg) {
        margin-bottom: bootstrap.$spacer*0.5;
    }

    @include bootstrap.media-breakpoint-up(sm) {
        display: inline-block;
        min-height: 8rem;
        background-color: transparent;
        background-image: functions.get-svg('quote', colors.$quote);
        background-position: top right;
        background-repeat: no-repeat;
        background-size: auto 8rem;
        line-height: bootstrap.$line-height-base*.9;
    }

    @include bootstrap.media-breakpoint-up(md) {
        //the below like has changed from 70% to 10rem as per CPA requirements for this breakpoint to make the quotes graphic appear smaller
        background-size: auto 10rem;
        padding-top: bootstrap.$spacer;
        padding-right: $quote-padding*0.5;
        min-height: 10rem;
    }

    @include bootstrap.media-breakpoint-up(xl) {
        padding-top: bootstrap.$spacer*2;
        padding-right: $quote-padding;
        line-height: bootstrap.$line-height-base;
    }
}

.quote > .blockquote-footer {
    &::before {
        content: ""; // Remove dash inserted by BS
    }

    > p {
        margin-bottom: 0;
    }
}
