@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use "vendors/bootstrap";


.accordion {
    margin-bottom: bootstrap.$spacer;

    .card {
        border-top: solid calc(bootstrap.$border-width/2) functions.theme-color-tint('primary', '15');
    }
    .card:first-of-type {
        border-top-color: transparent;
    }
}

.accordion-spaced .accordion > .card {
    margin-bottom: map-get(bootstrap.$spacers, 2);
    border-top: 0;
    @include bootstrap.border-radius(bootstrap.$card-border-radius);
}