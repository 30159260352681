/* 
    Bootstrap Variables ONLY 

    This file is based off node_modules/bootstrap/_variables.scss
    and node_modules/bootstrap/vendor/_rfs.scss...

    PLEASE be mindful of the section and variable order!

    If you need to create new variables specifically for this project, consider
    using the project's main.scss file, an existing .scss file, or 
    creating an abstracts/_variables.scss.
*/

@use 'abstracts/colors';
@use 'abstracts/variables';

/* RFS */

// Base font size
$rfs-base-font-size:    1.25rem;
$rfs-font-size-unit:    rem;

// Breakpoint at where font-size starts decreasing if screen width is smaller
$rfs-breakpoint:        75em; // see also $grid-breakpoints
$rfs-breakpoint-unit:   em;


/* Bootstrap Variables */

// Options

$enable-caret:                      false;
$enable-rounded:                    true;
$enable-shadows:                    true;
$enable-responsive-font-sizes:      true;


// Spacing

$spacer:                            2rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2.5)
);

// Body

$body-color:                        map-get(colors.$theme-colors, 'text');


// Links

$link-color:                        map-get(colors.$theme-colors, 'primary');
$link-decoration:                   underline;
$link-hover-color:                  map-get(colors.$theme-colors, 'dark');


// Paragraphs

$paragraph-margin-bottom:           $spacer;


// Grid breakpoints
// https://zellwk.com/blog/media-query-units/
$grid-breakpoints: (
  xs: 0,
  sm: 36em,     // 576 @ 16px
  md: 48em,     // 768 @ 16px
  lg: 62em,     // 992 @ 16px
  xl: 75em      // 1200 @ 16px
);


// Grid containers
// https://zellwk.com/blog/media-query-units/
$container-max-widths: (
  sm: 33.75em,  // 540 @ 16px
  md: 45em,     // 720 @ 16px
  lg: 60em,     // 960 @ 16px
  xl: 71.25em   // 1140 @ 16px
);

$grid-gutter-width:                 $spacer;


// Components

$line-height-sm:                    1.2;

$border-width:                      .125rem;

$border-radius:                     .5rem;
$border-radius-lg:                  .8rem;
$border-radius-sm:                  .25rem;

$box-shadow-sm:                     0 .2rem .5rem rgba(map-get(colors.$theme-colors, 'primary'), .1);
$box-shadow:                        0 .35rem .75rem rgba(map-get(colors.$theme-colors, 'primary'), .125), 
                                    0 2.5rem 1rem -2rem rgba(map-get(colors.$theme-colors, 'primary'), 0.18);
$box-shadow-lg:                     0 .5rem 1rem rgba(map-get(colors.$theme-colors, 'primary'), .15);
                

// Typography

$font-family-base:                  "proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base:                    1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                      $font-size-base * 1.25;
$font-size-sm:                      $font-size-base * .875;

$line-height-base:                  1.8;

$h1-font-size:                      $font-size-base * 3.5;  // 56
$h2-font-size:                      $font-size-base * 2.75; // 44
$h3-font-size:                      $font-size-base * 2.25; // 36
$h4-font-size:                      $font-size-base * 1.5;  // 24
$h5-font-size:                      $font-size-base * 1.25; // 20
$h6-font-size:                      $font-size-base;        // Body Heading 16

$headings-margin-bottom:            $spacer;
$headings-font-family:              "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight:              700;
$headings-line-height:              $line-height-sm;
$headings-color:                    map-get(colors.$theme-colors, 'primary');

$display1-size:                     5rem;
$display2-size:                     4.5rem;
$display3-size:                     4rem;
$display4-size:                     3.5rem; // Same as H1

$display1-weight:                   700;
$display2-weight:                   700;
$display3-weight:                   700;
$display4-weight:                   700;

$lead-font-size:                    $font-size-base;

$blockquote-small-color:            map-get(colors.$theme-colors, 'text');
$blockquote-small-font-size:        $font-size-base * 1.25;
$blockquote-font-size:              $font-size-base * 2.25;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-focus-width:             0;
$input-btn-focus-box-shadow:        none;

// Buttons

$btn-padding-y:                     .625rem;
$btn-padding-x:                     .875rem;
$btn-font-size:                     $font-size-sm;
$btn-line-height:                   $line-height-sm;

$btn-box-shadow:                    none;
$btn-active-box-shadow:             none;

$btn-border-radius:                 2rem;
$btn-border-radius-lg:              2rem;
$btn-border-radius-sm:              2rem;


// Forms (see also: forms.scss)

$input-padding-x:                                   1rem;

$input-color:                                       map-get(colors.$theme-colors, 'text');
$input-border-color:                                mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 70%);

$input-focus-border-color:                          map-get(colors.$theme-colors, 'primary');

$input-placeholder-color:                           mix(colors.$white, map-get(colors.$colors, 'gray'), 40%);

$form-grid-gutter-width:                            .75rem;

$custom-control-indicator-size:                     1.5rem;

$custom-control-indicator-border-color:             mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 70%);

$custom-control-indicator-checked-color:            map-get(colors.$theme-colors, 'primary');
$custom-control-indicator-checked-bg:               colors.$white;
$custom-control-indicator-checked-border-color:     mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 70%);

$custom-control-indicator-focus-border-color:       map-get(colors.$theme-colors, 'primary');

$custom-control-indicator-active-border-color:      map-get(colors.$theme-colors, 'primary');

// interface/icons/themeable/checkmark.svg
$custom-checkbox-indicator-icon-checked:            url("data:image/svg+xml,<svg width='11' height='8' viewBox='0 0 11 8' xmlns='http://www.w3.org/2000/svg'><path fill='#{$custom-control-indicator-checked-color}' d='M3.19212 7.64524L0.318192 4.75134C-0.12146 4.30865 -0.105354 3.61476 0.365568 3.18632C0.852594 2.77302 1.59072 2.80242 2.03038 3.24511L4.0562 5.26796L8.95581 0.351241C9.41157 -0.0914513 10.1497 -0.120844 10.6206 0.292453C11.1076 0.720894 11.1228 1.41478 10.6832 1.85747L4.91928 7.64549C4.69945 7.86728 4.38583 8 4.05513 8C3.74149 7.99911 3.41177 7.86639 3.19194 7.64549L3.19212 7.64524Z' /></svg>");

$custom-checkbox-indicator-border-radius:           $border-radius-sm; 

$custom-select-padding-x:                           .5rem;
$custom-select-color:                               map-get(colors.$theme-colors, 'primary');
$custom-select-bg-size:                             .5rem 0.625rem;
$custom-select-indicator-color:                     map-get(colors.$theme-colors, 'dark');


// Form validation

$form-feedback-valid-color:         map-get(colors.$theme-colors, 'primary');
$form-feedback-invalid-color:       map-get(colors.$brand-colors, 'alert');

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
// interface/icons/themeable/checkmark-circle.svg
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M12 23.97a12 12 0 10-12-12 12 12 0 0012 12' fill='#{mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%)}'/><path fill='none' stroke='#{$form-feedback-icon-valid-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M20.01 6.57L9.14 17.43h0l-5.23-5.22'/></svg>");
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
// interface/icons/themeable/x-mark-circle.svg
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M12 23.97a12 12 0 10-12-12 12 12 0 0012 12' fill='#{mix(colors.$white, map-get(colors.$theme-colors, 'danger'), 85%)}' data-name='Layer 4'/><g data-name='X mark' fill='none' stroke='#{$form-feedback-icon-invalid-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'><path d='M5.46 5.49l13.01 13.02M18.56 5.4L5.37 18.6'/></g></svg>");


// Navs

$nav-link-padding-y:                0.375rem;
$nav-link-padding-x:                .5rem;

$nav-tabs-border-color:             colors.$white;
$nav-tabs-border-radius:            $border-radius;
$nav-tabs-link-hover-border-color:  colors.$white;
$nav-tabs-link-active-color:        map-get(colors.$theme-colors, 'primary');
$nav-tabs-link-active-bg:           mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%);
$nav-tabs-link-active-border-color: colors.$white;

$nav-pills-link-active-color:       map-get(colors.$theme-colors, 'primary');
$nav-pills-link-active-bg:          mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%);


// Navbar

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;

$navbar-nav-link-padding-x:         .5rem;

$navbar-brand-padding-y:            0;

$navbar-toggler-padding-y:          .25rem;
$navbar-toggler-padding-x:          .25rem;
$navbar-toggler-font-size:          1.25rem;
$navbar-toggler-border-radius:      $border-radius-sm;

$navbar-light-color:                    map-get(colors.$brand-colors, 'main');
$navbar-light-hover-color:              map-get(colors.$brand-colors, 'main');
$navbar-light-active-color:             map-get(colors.$brand-colors, 'main');
$navbar-light-toggler-border-color:     map-get(colors.$brand-colors, 'main');


// Dropdowns

$dropdown-padding-y:                0;
$dropdown-spacer:                   0;
$dropdown-border-radius:            0;
$dropdown-border-width:             0;
$dropdown-box-shadow:               0 1rem 1rem -.5rem rgba(map-get(colors.$theme-colors, 'primary'), .15);

$dropdown-link-color:               map-get(colors.$theme-colors, 'primary');
$dropdown-link-hover-color:         map-get(colors.$theme-colors, 'primary');
$dropdown-link-hover-bg:            mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%);

$dropdown-link-active-color:        map-get(colors.$theme-colors, 'primary');
$dropdown-link-active-bg:           mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%);

$dropdown-item-padding-y:           .375rem;
$dropdown-item-padding-x:           1.5rem;

// Jumbotron

$jumbotron-padding:                 0;
$jumbotron-bg:                      transparent;


// Cards

$card-spacer-y:                     $spacer;
$card-spacer-x:                     $spacer;
$card-border-width:                 0;
$card-border-color:                 map-get(colors.$theme-colors, 'primary');
$card-cap-bg:                       mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 95%);
$card-cap-color:                    map-get(colors.$theme-colors, 'primary');


// Tooltips

$tooltip-max-width:                 12.5rem;


// Popovers

$popover-max-width:                 17.25rem;


// Toasts

$toast-max-width:                   22rem;
$toast-border-width:                $border-width;


// Badges

$badge-border-radius:               0;
$badge-font-size:                   87.5%;
$badge-font-weight:                 normal;
$badge-padding-y:                   $btn-padding-y;
$badge-padding-x:                   $btn-padding-x;

$modal-fade-transform:              translate(0, -3.125rem);


// Modals

$modal-xl:                          71.25rem;
$modal-lg:                          50rem;
$modal-md:                          31.25rem;
$modal-sm:                          18.75rem;


// Alerts

$alert-padding-y:                   .375rem;
$alert-padding-x:                   .75rem;

$alert-bg-level:                    -10;
$alert-border-level:                -10;
$alert-color-level:                 0;


// Progress bars

$progress-bg:                       mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%);
$progress-box-shadow:               none;
$progress-bar-bg:                   map-get(colors.$theme-colors, 'primary');


// List group

$list-group-border-color:           mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%);
$list-group-border-width:           calc($border-width / 2);

$list-group-item-padding-y:         calc($spacer/2);
$list-group-item-padding-x:         calc($spacer/2);

$list-group-hover-bg:               mix(colors.$white, map-get(colors.$theme-colors, 'primary'), 85%);

$list-group-action-color:           $link-color;
$list-group-action-hover-color:     $link-hover-color;

$list-group-action-active-color:    colors.$white;
$list-group-action-active-bg:       $link-color;


// Figures

$figure-caption-font-size:          90%;
$figure-caption-color:              map-get(colors.$theme-colors, 'text');


// Carousel

$carousel-indicator-width:              1.875rem;
$carousel-indicator-height:             0.1875rem;
$carousel-indicator-hit-area-height:    0.625rem;
$carousel-indicator-spacer:             0.1875rem;

$carousel-control-icon-width:           1.25rem;


// Code

$pre-scrollable-max-height:             21.25rem;