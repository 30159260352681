@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'base/layout';
@use 'vendors/bootstrap';

#nav-secondary-centering {
    display: none;
}

#nav-secondary {
    background-color: functions.tint(functions.brand-color(), 95);

    .nav-link{
        color: functions.brand-color();

        &.external-link:after {
            background-image: functions.get-svg('external-link', functions.brand-color());
        }
    }
}

// Tablet+
@include bootstrap.media-breakpoint-up(md) {

    #nav-secondary {
        display: flex;
        align-self: stretch;
        align-items: center;
        padding: .375rem 0;
        background-color: functions.tint(bootstrap.theme-color(), 95);

        & > .navbar-nav {
            flex: 1 1 auto;
            justify-content: space-around;
        }

        .nav-item {
            margin-bottom: 0;
        }

        .nav-link{
            color: bootstrap.theme-color();
            font-size: bootstrap.$font-size-sm;
            text-decoration: none;

            &.external-link:after {
                background-image: functions.get-svg('external-link', bootstrap.theme-color());
            }
        }

        a:not(.btn) {
            border-bottom: solid bootstrap.$border-width transparent;
        }

        a:not(.btn):hover,
        a:not(.btn):focus {
            background-color: transparent;
            border-bottom-color: bootstrap.theme-color();
        }

    }
}

@include bootstrap.media-breakpoint-up(lg) {
    #nav-secondary {
        & > .navbar-nav {
            justify-content: space-evenly;
        }
    }

}

@include bootstrap.media-breakpoint-up(xl) {

    // For centering in XL, see also #nav-support-sk (xl)
    #nav-secondary-centering {
        display: flex;
        flex: 1 1 auto;
        align-self: stretch;
        background-color: functions.tint(bootstrap.theme-color(), 95);
    }

    #nav-secondary {
        flex: 1 1 auto;
        justify-content: center;

        & > .navbar-nav {

            flex: 0 1 auto;

            .nav-link {
                white-space: nowrap;
                margin: 0 bootstrap.$navbar-nav-link-padding-x*2;
            }
        }
    }

}