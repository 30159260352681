@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

.figure {
    display: table;
}

.figure-img {
    @include bootstrap.border-radius(bootstrap.$border-radius);
}

.figure-caption {
    display: table-caption;
    caption-side: bottom;
    line-height: variables.$line-height-md;
    padding: 0 bootstrap.$grid-gutter-width*0.5;
    min-width: bootstrap.subtract(variables.$site-min-width, bootstrap.$grid-gutter-width);
}