/*
     SickKids Theme - Shared Colors 
*/


/*
    Internal Colors
    - Re-useable IN THIS FILE
    - Store all colors internally with a '_color-' prefix.
    - Use a double-digit numerical identifier, even for single 
      colors to help find and replace later.
    - Utility for HSL conversion: https://shemeerns.com/plugins/color-picker/
    - Info about HSL: https://sujansundareswaran.com/blog/why-hsl-is-better-than-hex-and-rgb
*/

    $_color-white:          hsl(0, 0%, 100%);

    $_color-grey-01:        hsl(0, 0%, 35%);    // Brand - Subtle / Text Color

    $_color-black:          hsl(0, 0%, 0%);

    $_color-blue-01:        #0067b1;            // Brand - Main, Badge - AboutKidsHealth
    $_color-blue-02:        #072C8A;            // Brand - Accent Dark, Badge - Seek Help
    $_color-blue-03:        #2D73C6;            // Badge - Need Help

    $_color-teal-01:        #00BEE1;            // Brand - Accent
    $_color-teal-02:        #2DD9EE;            // Badge - ForKids

    $_color-red-01:         #DB2E2E;            // Brand - Alert

    $_color-orange-01:      #F58E57;            // Badge - Research

    $_color-green-01:       #09695C;            // Brand - Success, Badge - Provide Help
    $_color-green-03:       #00D456;            // Badge - 

    $_color-purple-01:      #5442A5;            // Badge - Want To Help
    $_color-purple-02:      #7b0069;            // Badge - 
    $_color-purple-03:      #E4ECFF;            // Quote icon

    $_color-magenta-01:     #B256D2;            // Badge - Opportunities


/* 
    Public Colors 
    - Create human-readable names for using colors in code.
    - Do not use private color variables in your code!
*/

    $white: $_color-white;
    $black: $_color-black;

    // Brand Colors - Naming based on Styleguide!
    $default-brand-colors: ( 
        'main':          $_color-blue-01, 
        'accent':        $_color-teal-01,
        'dark':          $_color-blue-02,
        'subtle':        $_color-grey-01, 
        'alert':         $_color-red-01, 
        'success':       $_color-green-01
    );
    $brand-colors: () !default;
    $brand-colors: map-merge($default-brand-colors, $brand-colors);

    // Badge Borders
    $badge-colors: (

        // Journeys
        'need-help':            $_color-blue-03,
        'seek-help':            $_color-blue-02,
        'provide-help':         $_color-green-01,
        'want-to-help':         $_color-purple-01,

        // Keyword Colors
        'foo':                  $_color-purple-02,
        'aboutkidshealth':      $_color-blue-01,
        'research':             $_color-orange-01,
        'forkids':              $_color-teal-02,
        'opportunities':        $_color-magenta-01,
        'bar':                  $_color-green-03

    );

    // Other elements

    $quote: $_color-purple-03;


/* 
    Bootstrap Color Maps
    - Colors map for Bootstrap themeing.
    - IMPORTANT: This limited set of APPROVED styleguide colors >>OVERRIDES<<
      the Bootstrap built-in colors list, SEE: vendors/_bootstrap.scss
    - See: https://getbootstrap.com/docs/4.5/getting-started/theming/#color
           https://getbootstrap.com/docs/4.5/getting-started/theming/#theme-colors
*/

    $colors: (
        'blue':             $_color-blue-01,
        'red':              $_color-red-01,
        'green':            $_color-green-01,
        'gray':             $_color-grey-01
    );

    // WARNING: Be cautious when using secondary as it may cause contrast issues!
    $default-theme-colors: ( 
        'primary':          $_color-blue-01, 
        'secondary':        $_color-teal-01,
        'dark':             $_color-blue-02,
        'danger':           $_color-red-01, 
        'success':          $_color-green-01, 
        'text':             $_color-grey-01
    );
    $theme-colors: () !default;
    $theme-colors: map-merge($default-theme-colors, $theme-colors);


/* 
    Color Shades 
    - Central storage for tint values re-use & consistency.
    - See: abstracts/functions.scss
*/

    $color-tints: ( 
        '60': 40%, 
        '30': 70%, 
        '15': 85% 
    );