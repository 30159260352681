@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


/* 
    See also basic-elements.scss 
*/

    body {
        min-width: variables.$site-min-width;
        max-width: variables.$site-max-width;
        margin: 0 auto;
    }


    // Fixed header padding FALL-BACK for jump-links
    // - In some browsers when you don't change the hash,
    //   but hit enter, or for other reasons, the browser will go 
    //   to the native location and not trigger hashchange
    :target
    { 
        scroll-snap-margin-top: 5rem; // Safari
        scroll-margin-top: 5rem;

        @include bootstrap.media-breakpoint-up(md){
            scroll-snap-margin-top: 10rem;
            scroll-margin-top: 10rem;
        }

        @include bootstrap.media-breakpoint-up(xl){
            scroll-snap-margin-top: 7rem;
            scroll-margin-top: 7rem;
        }
    }

    .news-detail-page :target
    { 
        scroll-snap-margin-top: 8rem; // Safari
        scroll-margin-top: 8rem;

        @include bootstrap.media-breakpoint-up(md){
            scroll-snap-margin-top: 13rem;
            scroll-margin-top: 13rem;
        }

        @include bootstrap.media-breakpoint-up(xl){
            scroll-snap-margin-top: 13rem;
            scroll-margin-top: 13rem;
        }
    }


/*
    Component Spacing
    - The project follows a pattern of 'margin-bottom' spacing, so try and
      avoid using 'padding-top' / 'margin-top', you want 
      the layout to flow downwards for the most part.
    - .content-area can be used to apply "Standard Margins" on any page/content area property, but recall, 
      most individual components have their own "Content Spacing" option as well
*/

    .content-area {
        margin-bottom: variables.$component-margin-lg;

        // Pad most "generic" content pages that don't use a jumbotron
        & > :first-child:not(.jumbotron):not(#article-toolbar):not(.content-area) {
            margin-top: variables.$component-margin;
        }

        & > .jumbotron,
        & > .container:not(.content-area),
        & > .container-fluid:not(.content-area) {
            margin-bottom: variables.$component-margin;

            @include bootstrap.media-breakpoint-up(md){
                margin-bottom: variables.$component-margin-lg;
            }
        }

        @each $breakpoint, $container-max-width in bootstrap.$container-max-widths {
            .container-#{$breakpoint}:not(.content-area) {
                margin-bottom: variables.$component-margin;

                @include bootstrap.media-breakpoint-up(md){
                    margin-bottom: variables.$component-margin-lg;
                }
            }
        }

        // The Cards .col have a necessary bottom gutter for rows of cards (2rem),
        // (AND the bottom .rich-text field happens to have a bottom gutter (2rem)),
        // so we need to subtract a bit for consistency with the above standard gaps (5rem-2rem).
        & > .card-collection:not(.content-area) {
            //margin-bottom: variables.$component-margin - bootstrap.$grid-gutter-width;

            @include bootstrap.media-breakpoint-up(md){
                margin-bottom: variables.$component-margin-lg - bootstrap.$grid-gutter-width;
            }
        }

        // Remove extra margin on nested content areas (the interior components will have margin)
        & > .content-area {
            margin-bottom: 0;
        }
    }


/*
    Page-level utilities
*/

    // Keyboard focus button styles for top of site
    .page-top-tab-link {
        position: absolute;
        z-index: bootstrap.$zindex-fixed;
        transform: translateY(-150%);
        display: block;
        width: 100%;
        background-color: functions.tint(functions.brand-color(), 97);
        padding: bootstrap.$spacer*0.5 bootstrap.$spacer;
        text-align: center;
        @include bootstrap.transition();

        &:focus {
            transform: translateY(0%);
        }

        @include bootstrap.media-breakpoint-up(md){
            text-align: left;
        }

        & > img, & > svg  {
            width: 2rem;
            height: 2rem;
            vertical-align: middle;
        }
    }

    .no-js .page-top-tab-link {
        position: relative;
        transform: translateY(0%);
    }

    .no-js #go-to-sitemap {
        font-size: bootstrap.$font-size-lg;
        font-weight: bootstrap.$font-weight-bold;
    }

    // Named anchor for top of site
    #top {
        display:block;
        height: 0;
    }

    // Floating back to top link
    // Notes:   We use body text align right as right: 0 is not working cross-browser with position: sticky. 
    //          Changing the implementation to, say, a block div with text-align right will overlap content,
    //          which is particularly a concern when tabbing between anchors on the page.

    #Breadcrumb {
        font-size: 0.8rem;
        padding-bottom: 0.8rem;
    }

    #back-to-top {
        bottom: 0px;
        right: 0px;
        width: auto;
        margin-left: auto;
        display: inline-block;
        color: functions.brand-color();
        background-color: colors.$white; 
        padding: map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 2);
        font-size: bootstrap.$font-size-sm;
        text-align: center;

        @include bootstrap.media-breakpoint-up(md){
            text-align: right;
        }

        & > img, & > svg {
            line-height: 1;
            width: 2em;
            height: 2em;
            vertical-align: bottom;
        }

        @include bootstrap.hover-focus(){
            background-color: functions.tint(functions.brand-color(), 85);
            text-decoration: bootstrap.$link-hover-decoration;
        }
    }
@media print {
    .d-display-none {
        position: initial !important;
    }
}