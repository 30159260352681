@use 'abstracts/colors';
@use 'abstracts/mixins';
@use 'vendors/bootstrap';

.facets {
    justify-content: center;

    padding: map-get(bootstrap.$spacers, 1);
    @include bootstrap.media-breakpoint-up(sm){
        padding: map-get(bootstrap.$spacers, 1) bootstrap.$spacer;
    }


    background-color: colors.$white;
    @include bootstrap.box-shadow(bootstrap.$box-shadow);
    @include bootstrap.border-radius(bootstrap.$border-radius-lg);

    .btn-link {
        flex-basis: 50%;
        margin: 0;

        @include mixins.ie11(){
        flex-basis: 40%;
        }

        @include bootstrap.media-breakpoint-up(md){
            flex-basis: auto;
            margin: 0 map-get(bootstrap.$spacers, 2);
        }
    }

    .facet-row-break {
        height: 0;
    }
}

@include bootstrap.media-breakpoint-between(md, lg) {

    .facet-row-break {
        flex-basis: 100%;
    }
}