@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

.listing-group {

    .list-group {
        margin: bootstrap.$spacer 0;
    }
    
    .list-group > :last-child,
    & > .row > .col > :last-child {
        margin-bottom: 0;
    }

    .list-group-flush {
        @include bootstrap.box-shadow(bootstrap.$box-shadow);
    }

    .list-group-item-action {
        font-weight: bootstrap.$font-weight-bold;
        padding-right: bootstrap.$list-group-item-padding-x*2;

        &:focus {
            background-color: functions.theme-color-tint('primary','15');
        }

        & .title {
            display: inline-block;
            text-decoration: bootstrap.$link-decoration;
            padding-right: bootstrap.$list-group-item-padding-x;
            line-height: variables.$line-height-md;
        }

        & .subtitle {
            display: block;
            text-decoration: none;
            font-weight: bootstrap.$font-weight-normal;
            color: functions.brand-color('subtle');
            /*line-height: bootstrap.$line-height-sm;*/
        }
    }

    .list-group-item-action:not(.external-link) .link-icon {
        position: absolute;
        top: 50%;
        right: bootstrap.$list-group-item-padding-x;
        width: 0.5rem;
        height: 0.5rem;
        border: solid bootstrap.theme-color();
        border-width: 0 bootstrap.$border-width bootstrap.$border-width 0;
        pointer-events: none;
        transform: translateY(-60%) rotate(-45deg);
    }

    .list-group-item-action.external-link:after {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: bootstrap.$list-group-item-padding-x - .25rem;
    }
}

.listing-group .list-group.list-group-spaced {
    .list-group-item {
        margin-bottom: bootstrap.$list-group-item-padding-y;
        @include bootstrap.box-shadow(bootstrap.$box-shadow);
        border: none;
        border-radius: 0;
    }

    & > :last-child {
        margin-bottom: 0;
    }
}