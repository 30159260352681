@use 'abstracts/colors';
@use 'vendors/bootstrap';

.FormFileUpload {
    @extend .custom-file;
    
    &:hover, &:focus, &:active {
        & .Form__Element__Caption {
            border-color: bootstrap.theme-color();
        }
    }


    & .Form__Element__Caption {
        @extend .custom-file-label;
        
    }
    
    & .FormFileUpload__Input {
        @extend .custom-file-input;
    }
}