@use 'abstracts/variables';
@use "vendors/bootstrap";

.yt-embed {

    margin-bottom: bootstrap.$spacer;

    .card-collapse .card-header {
        margin-bottom: 0;
        @include bootstrap.border-top-radius(0);
        @include bootstrap.border-bottom-radius(bootstrap.$card-border-radius);
    }

    .collapse, .collapsing {
        padding: map-get(bootstrap.$spacers, 1);
    }

    .card-collapse .card-body {
        max-height: 25rem;
        overflow-y: auto;
    }
}

.yt-embed-intext {
    /*margin-bottom: bootstrap.$spacer;*/

    .card-collapse .card-header {
        margin-bottom: 0;
        @include bootstrap.border-top-radius(0);
        @include bootstrap.border-bottom-radius(bootstrap.$card-border-radius);
    }

    .collapse, .collapsing {
        padding: map-get(bootstrap.$spacers, 1);
    }

    .card-collapse .card-body {
        max-height: 25rem;
        overflow-y: auto;
    }
}