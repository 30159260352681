@use 'abstracts/colors';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

$backround-padding: .15rem;

.pagination {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    .background {
        display: inline-flex;
        align-items: center;
        padding: bootstrap.$input-padding-y*0.5; // matches Question Form
        background-color: colors.$white;
        border: solid bootstrap.$border-width colors.$white;
        @include bootstrap.border-radius(bootstrap.$btn-border-radius);
        @include bootstrap.box-shadow(bootstrap.$box-shadow);
    }

    .pages {
        font-size: 0; // Hack to remove trailing whitespace after inline-block items
        padding: $backround-padding;
    }

    .page {
        margin: 0;
        padding: variables.$btn-circle-padding;
        line-height: 1;
        display: inline-flex;
        width: auto;
        max-width: none;
        font-size: bootstrap.$font-size-base;
    }

    .active {
        text-decoration: none;
    }

    .controls {
        display: flex;
        flex-wrap: nowrap;
        margin-left: bootstrap.$btn-padding-x;
        padding: $backround-padding 0;

        .btn {
            line-height: 1;

            & > svg {
                width: variables.$btn-icon-size-xl;
                height: variables.$btn-icon-size-xl;
            }
        }
    }

    span.page {
        display: inline-block;
        min-width: variables.$btn-icon-size;
        min-height: variables.$btn-icon-size;
        line-height: 1;
    }
}


@include bootstrap.media-breakpoint-down(xs) {

    .pagination {

        .pages {
            padding: $backround-padding 0;
        }

        .page.hide-in-mobile {
            display: none;
        }

        .controls {
            margin-left: bootstrap.$btn-padding-x*0.25;

            .btn {
                margin-right: bootstrap.$btn-padding-y*0.25;

                + .btn {
                    margin-right: 0;
                }
            }
        }
    }
}


@include bootstrap.media-breakpoint-up(sm) {

    .pagination {
        .page.hide-in-tablet {
            display: none;
        }
    }
}


@include bootstrap.media-breakpoint-up(md) {

    .card-collection + .pagination {
        margin-top: -1*variables.$component-margin;
    }
}