@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


$article-spacer: bootstrap.$spacer;
$article-spacer-lg: bootstrap.$spacer*1.5;

.news-detail-page {

    article {
        padding-top: $article-spacer;

        footer {
            border-top: bootstrap.$border-width solid functions.theme-color-tint('primary', '15');
            margin-top: bootstrap.$spacer;
            padding-top: bootstrap.$spacer;
            @include bootstrap.media-breakpoint-up(xl){
                margin-top: bootstrap.$spacer*2;
                padding-top: bootstrap.$spacer*2;
            }
        }
    }

    h1 {
        margin-bottom: $article-spacer;

        @include bootstrap.media-breakpoint-up(xl){
            margin-bottom: $article-spacer-lg;
        }
    }
}

.article-meta {
    margin-bottom: $article-spacer;

    @include bootstrap.media-breakpoint-up(xl){
        margin-bottom: $article-spacer-lg;
    }

    p {
        margin-bottom: 0;
    }
}

.article-summary {
    margin-bottom: $article-spacer;

    p {
        margin-bottom: 0;
        padding-left: $article-spacer*0.5;

        @include bootstrap.media-breakpoint-up(md){
            padding-left: $article-spacer;
        }

        @include bootstrap.media-breakpoint-up(xl){
            padding-left: $article-spacer-lg;
        }
    }

    .summary-text {
        margin-left: -.25rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        border-left: .25rem solid functions.theme-color-tint('primary', '15');
        color: bootstrap.theme-color('dark');
        background-color: transparent;

        @include bootstrap.media-breakpoint-up(md){
            padding-bottom: $article-spacer;
        }

        @include bootstrap.media-breakpoint-up(xl){
            padding-bottom: $article-spacer-lg;
        }
    }
}

#article-toolbar {
    top: 0;
    left: 0;
    z-index: bootstrap.$zindex-sticky;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-top: map-get(bootstrap.$spacers, 1);
    background-color: colors.$white;
    @include bootstrap.box-shadow(bootstrap.$box-shadow-sm);
    border-top: calc(bootstrap.$border-width / 2) solid functions.theme-color-tint('primary','30');

    .progress {
        flex-basis: 100%;
        margin-top: map-get(bootstrap.$spacers, 1);
    }
}

.article-tools {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .article-title {
        display: none;
        color: bootstrap.theme-color();
        font-size: bootstrap.$font-size-sm;
        line-height: variables.$line-height-md;
    }

    .time-to-read {
        display: none;
        color: bootstrap.theme-color();
        font-weight: bootstrap.$font-weight-bold;
        font-size: bootstrap.$font-size-sm;
        white-space: nowrap;
    }

    .share-links {
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;

        & > .btn-toolbar {
            flex-wrap: nowrap;

            > :last-child {
                margin-right: 0;
            }
        }

        & > .share-title {
            color: bootstrap.theme-color();
            margin-right: map-get(bootstrap.$spacers, 1);
            font-size: bootstrap.$font-size-sm;
        }
    }
}

#resize-article-text {
    margin: 0 0 0 bootstrap.$spacer;
    @include mixins.btn-icon-accent();
        & > svg {
            width: 1.875em;
            height: 1.875em;
        }

        &:focus {
            border-color: bootstrap.theme-color();
        }
}


@include bootstrap.media-breakpoint-up(sm){
    .article-tools {
        
        .share-links {
            margin-left: auto;
        }

        .time-to-read {
            display: block;
        }
    }
}


@include bootstrap.media-breakpoint-up(lg){
    .article-tools {

        .article-title {
            display: block;
            flex-basis: 50%;
        }

        .time-to-read {
            margin-left: auto;
        }
    }
}

@include bootstrap.media-breakpoint-up(xl){
    .article-tools {

        .article-title {
            flex-basis: auto;
            padding-right: map-get(bootstrap.$spacers, 2);

            @include mixins.ie11(){
                flex-basis: 50%;
            }
        }

        .time-to-read {
            margin: 0 map-get(bootstrap.$spacers, 2) 0 auto;
        }
    }
}


/* Extra styles for non-JavaScript fall-back */

.no-js {
    #resize-article-text {
        display: none;
    }
}