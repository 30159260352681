@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'vendors/bootstrap';


label {
    color: bootstrap.theme-color();
}

.custom-control-input:focus:checked ~ .custom-control-label::before {
    border-color: bootstrap.$custom-control-indicator-focus-border-color;
}

// Pair this with $custom-control-indicator-size
// Recommend trying to get EVEN numbers (1.5rem=24px,75%=18px)
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-size: 75% 75%;
}

// Custom disabled borders
.form-control:disabled, .form-control[readonly],
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
    border-color: functions.tint(functions.brand-color('subtle'), 60);
 }

/* 
    Invalid styling for custom checkbox/radio 
    ....Strange that Bootstrap is missing these tbh
*/

.custom-control-input.is-invalid:active ~ .custom-control-label:before {
    background-color: colors.$white;
    border-color: bootstrap.$form-feedback-invalid-color;
}

// Checkbox specific
.custom-checkbox {

    .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
        border-color: colors.$white;
    }

    .custom-control-input.is-invalid:checked ~ .custom-control-label:after {
        background-image: bootstrap.escape-svg(url("data:image/svg+xml,<svg width='11' height='8' viewBox='0 0 11 8' xmlns='http://www.w3.org/2000/svg'><path fill='#{colors.$white}' d='M3.19212 7.64524L0.318192 4.75134C-0.12146 4.30865 -0.105354 3.61476 0.365568 3.18632C0.852594 2.77302 1.59072 2.80242 2.03038 3.24511L4.0562 5.26796L8.95581 0.351241C9.41157 -0.0914513 10.1497 -0.120844 10.6206 0.292453C11.1076 0.720894 11.1228 1.41478 10.6832 1.85747L4.91928 7.64549C4.69945 7.86728 4.38583 8 4.05513 8C3.74149 7.99911 3.41177 7.86639 3.19194 7.64549L3.19212 7.64524Z' /></svg>"));
    }
}

// Radios specific
.custom-radio {

    .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
        background-color: colors.$white;
    }

    .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
        border-color: bootstrap.$form-feedback-invalid-color;
    }

    .custom-control-input.is-invalid:checked ~ .custom-control-label:after {
        background-image: bootstrap.escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{bootstrap.$form-feedback-invalid-color}'/></svg>"));
    }
}
