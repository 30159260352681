@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


$card-spacer-y: bootstrap.$card-spacer-y*0.5;

.card {
    @include bootstrap.box-shadow(bootstrap.$box-shadow);
    &:hover {
        @include mixins.box-shadow-xl();
    }
    @include bootstrap.transition();
}

.card-header {
    padding: $card-spacer-y bootstrap.$card-spacer-x;
}

.card-footer {
    padding-top: 0;
    background-color: colors.$white;
}

.card-title,
.card-subtitle,
.card-text, 
.card-text > p {
    margin-bottom: $card-spacer-y;
}

.card-title,
.card-subtitle {
    &:last-child {
        margin-bottom: 0;
    }
}

.card-subtitle {
    @include bootstrap.font-size(bootstrap.$font-size-sm);
}

.card-lead {
    line-height: bootstrap.$line-height-sm;
}

//This is to pop the email links out to be clickable on a card that has stretched-link
.card a[href*="mailto:"] {
    position: relative;
    z-index: 20;
}

.card-bestBet{
    border:1px solid red;
}