@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


/* Base Question Form */

$question-form-spacer-y: .5rem;

.question-form {

    text-align: center;

    label {
        margin-bottom: map-get(bootstrap.$spacers, 2);
    }

    // Wrapping white rounded box
    .input-wrap {
        display: flex;
        background-color: colors.$white;
        margin: 0 0 map-get(bootstrap.$spacers, 2) 0;
        // Intentional right-padding matches vertical:
        padding: 0 bootstrap.$input-padding-y*0.5 0 bootstrap.$input-padding-x;
        border: solid bootstrap.$border-width colors.$white;
        @include bootstrap.border-radius(bootstrap.$rounded-pill);
        @include bootstrap.box-shadow(bootstrap.$box-shadow-lg);
        &:hover {
            @include mixins.box-shadow-xl();
        }
    }

    &.focus .input-wrap {
        border-color: bootstrap.theme-color();
    }

    // Inner text input element
    .form-control {
        border-width: 0;
        padding-left: 0;
        @include bootstrap.box-shadow(none);

        &:focus {
            @include bootstrap.box-shadow(none);
        }

        &::placeholder {
            color: functions.brand-color('subtle');
        }

        &:focus::placeholder {
            color: colors.$white;
            opacity: 0;
        }
    }

    // Submit button
    .btn.btn-primary { 
        width: auto;
        // Need to match the height of inputs
        padding-top: bootstrap.$input-btn-padding-y;
        padding-bottom: bootstrap.$input-btn-padding-y;
    }

    .btn-arrow {
        width: auto; // override base button
        flex: 0 1 auto;
        margin: 0;
        align-self: center;
    }

    .subtext {
        display: none;
    }

    .invalid-feedback {
        margin: 0 0 map-get(bootstrap.$spacers, 2) 0;
    }

    .rich-text {
        margin-bottom: 0;
    }
}

.no-js .question-form {

    .form-control {
        padding-left: bootstrap.$input-padding-x;
        margin-right: bootstrap.$input-padding-x;

        &:focus {
            border: bootstrap.$border-width solid functions.theme-color-tint('primary','60');
        }
    }
}


@include bootstrap.media-breakpoint-down(sm){

    .question-form {
        .was-validated .form-control:invalid {
            flex: 1 1 auto;
        }
    }

    .question-form {
        .btn-primary {
            display: none;
        }
    }
}


@include bootstrap.media-breakpoint-up(md){

    .question-form {

        .btn-icon {
            display: none;
        }

        .subtext {
            display: block;
            margin-bottom: $question-form-spacer-y;
            font-size: bootstrap.$font-size-sm;
        }

        .input-wrap {
            padding: bootstrap.$input-padding-y*.75 bootstrap.$input-padding-y*.75 bootstrap.$input-padding-y*.75 bootstrap.$input-padding-x;
        }

        .input-wrap > .btn-primary { 
            padding: 0 bootstrap.$btn-padding-x*2;
        }
    }

    .question-form.question-large {

        .form-group {
            text-align: left;

            & > .link {
                margin-bottom: 0;
                padding-top: bootstrap.$btn-padding-y;
                text-align: right;
            }
        }

        .invalid-feedback {
            text-align: left;
        }
    }

    .question-form.question-small {

        .row {
            justify-content: center;
        }

    }
}

@include bootstrap.media-breakpoint-up(lg){


    .question-form.question-large {

        .input-wrap > .btn-primary { 
            padding: 0 bootstrap.$btn-padding-x*4;
        }
    }

}