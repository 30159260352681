@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'vendors/bootstrap';



@each $color, $value in colors.$badge-colors {
    .badge-#{$color} {
        @include bootstrap.badge-variant($value);

        @at-root a#{&} {
            @include bootstrap.hover-focus() {
                background-color: functions.theme-color-tint('primary', '15');
            }
        }
    }
}

@each $color, $value in bootstrap.$theme-colors {
    a.badge-#{$color} {
        @include bootstrap.hover-focus() {
            background-color: functions.theme-color-tint('primary', '15');
        }
    }
}


.badge > img, .badge > svg {
    margin: 0 bootstrap.$badge-padding-x 0 0;
    vertical-align: middle;
}

.badge {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-width: bootstrap.$border-width*1.5;
    @include bootstrap.border-radius(bootstrap.$border-radius-sm);
}