@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use "vendors/bootstrap";

/*
    The global body text-align: right causes the epi quickNavigator "invisible" span text to show,
    so we reset that specifically here since they don't specify it
*/
#epi-quickNavigator .epi-quickNavigator-editLink a span {
    text-align: left;
}

body {
    text-align: right; // Back-to-top
}

main, 
header, 
footer {
    text-align: left;
}


hr {
    height: bootstrap.$border-width;
    margin: bootstrap.$spacer 0;
    padding: 0;
    border: 0;
    background-color: functions.theme-color-tint('primary', '15');

    @include bootstrap.media-breakpoint-up(xl){
        margin: bootstrap.$spacer*2 0;
    }

}

h5, .h5, h6, .h6 {
    margin-bottom: bootstrap.$spacer*0.5;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

    & > a {
        color: bootstrap.$headings-color;
    }
}

a:focus {
    background-color: functions.theme-color-tint('primary', '15');
    text-decoration: bootstrap.$link-hover-decoration;
}


a[href] {
    word-break: break-word; //Need this for long links to break a line or else it expands the container
}


ul, ol {
    padding-left: bootstrap.$spacer;
}

// Utility to style DL like UL
.inline-dl {
    @include bootstrap.clearfix();
    /*padding-left: bootstrap.$spacer; - commented out for removing bullets on the department, clinica and service page and lining them up with the rest of the text*/
    
}

.inline-dl > dt {
    float: left;
    clear: both;
    padding-right: map-get(bootstrap.$spacers, 1);
    display: list-item;
    list-style-type:none;
}

.inline-dl > dd {
    float:left;
    margin-bottom: 0;
}