@use 'abstracts/colors';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


.jumbotron-standard {
    position: relative;

    .rich-text {
        margin-bottom: 0;
    }

    .pretitle {
        display: block;
        font-size: bootstrap.$font-size-sm;
    }

    .title {
        & > a {
            text-decoration: none;
        }
    }

    .jumbotron-bg {
        & > .badge {
            display: none;
        }
    }
}


@include bootstrap.media-breakpoint-down(sm){

    // We match the container gutters/spacing
    .jumbotron-standard {

        .jumbotron-body {
            padding-top: bootstrap.$grid-gutter-width*0.5;
        }

        &.hide-content-mobile .jumbotron-body {
            display: none;
        }

        .jumbotron-bg {
            position: relative;

            & > .badge {
                display: inline-block;
                position: absolute;
                top:  bootstrap.$grid-gutter-width*0.5;
                left: bootstrap.$grid-gutter-width*0.5;
            }
        }

        .jumbotron-body .badge {
            display: none;
        }
    }
}


@include bootstrap.media-breakpoint-up(md){

    .jumbotron-standard {

        .badge {
            margin-bottom: bootstrap.$spacer;
        }

        .jumbotron-body {
            padding: bootstrap.$grid-gutter-width*0.5 0;
        }
    }

    // Layout of image/body
    .jumbotron-standard > .jumbotron-layout-wrap {

        // Layout standard
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;

        .jumbotron-bg,
        .jumbotron-body {
            flex: none;
            width: 100%;
        }

        .jumbotron-img {
            width: 100%;
            height: 100%;
            @include mixins.object-fit(cover);
        }

        .jumbotron-body {
            margin-left: -100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            .row {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
}