@use 'abstracts/variables';
@use 'vendors/bootstrap';

.listing {
    position: relative;

    .title {
        text-align: center;
    }

    // Has a value in CMS but we cover it incase null
    .row {
        align-items: center;
    }

    .mixed-content,
    .fixed-content {
        @include bootstrap.make-col-ready();
        @include bootstrap.make-col(12);

        @include bootstrap.media-breakpoint-up(md) {
            @include bootstrap.make-col(6);
        }
    }

    .fixed-content > .container,
    .fixed-content > .container-md {
        padding: 0;
    }
}


/* Tablet+ */
@include bootstrap.media-breakpoint-up(md) {

    .listing.mixed-right {

        .fixed-content {
            order: 0;
        }

        .mixed-content{
            order: 1;

            & > picture { display: block; text-align: right; }
        }
    }

    .listing.container-fluid {
       
        .title {
            min-height: 3rem;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            white-space: nowrap;
        }
        
        .mixed-content {
            padding: 0 bootstrap.$spacer*0.5 0 0;
        }

        .fixed-content {
            margin-top: 3rem+bootstrap.$spacer;
        }
    }

    .listing.container-fluid.mixed-right .mixed-content {
        padding-left: bootstrap.$spacer*0.5;
        padding-right: 0;
    }
}


/* Desktop+ */
@include bootstrap.media-breakpoint-up(xl) {

    .listing.container-fluid.mixed-right {

        .fixed-content {
            margin-left: 12.5%;
        }
    }

    .listing.container-fluid .fixed-content {
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }
}