@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';



.sitemap-page {
    h1 {
        text-align: center;
    }

    .sitemap {
        .first-level, .col {
            margin-bottom: map-get(bootstrap.$spacers, 3);
        }

        .link-list {
            margin-top: bootstrap.$spacer*.75;
            list-style: none;
            padding: 0;
            line-height: variables.$line-height-md;

            li {
                margin-bottom: map-get(bootstrap.$spacers, 2);
            }

            a {
                color: bootstrap.$body-color;
            }
            }

            .link-list-less-space {
                margin-top: bootstrap.$spacer*.50;
                list-style: none;
                padding-left: 1em;
                line-height: variables.$line-height-md;

                li {
                    margin-bottom: map-get(bootstrap.$spacers, 2);
                }

                a {
                    color: bootstrap.$body-color;
                }
            }

            h2, h3 {
                margin-bottom: 0;
            }
        }
    }


    @include bootstrap.media-breakpoint-up(lg) {
        .sitemap-page {
            .sitemap {
                .link-list {
                    line-height: bootstrap.$line-height-sm;
                }

                .link-list-less-space {
                    line-height: bootstrap.$line-height-sm;
                }
            }
        }
    }




