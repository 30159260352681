@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


$social-small-icon-size: 1.875em;
$social-large-icon-size: 2.5em;

.btn-toolbar {
    align-items: flex-start;

    .btn {
        margin-left: 0;
        margin-right: 0.375em;

        & > svg {
            width: $social-small-icon-size;
            height: $social-small-icon-size;
        }

        &:focus {
            border-color: bootstrap.theme-color();
        }
    }

    &.brand-main {
        .btn {
            &:focus {
                border-color: functions.brand-color();
            }

            @include mixins.btn-icon-brand();
        }
    }
}

.btn-toolbar-social-small {
    .btn {
        @include mixins.btn-icon-accent();
    }
}

.btn-toolbar-social-large {

    .btn {
        margin: 0;
        @include mixins.btn-icon-accent();

        & > svg {
            width: $social-large-icon-size;
            height: $social-large-icon-size;
        }
    }

    .btn + .btn {
        margin-left: map-get(bootstrap.$spacers, 2);

        @include bootstrap.media-breakpoint-up(sm){ 
            margin-left: bootstrap.$spacer;
        }
    }
}