@use 'sass:list';
@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'vendors/bootstrap';


/* 
    Utility classes for SVG path colors
    - Also generates tints from styleguide
*/


    // Defaults for two-tone icons
    .svg-stroke-fg {
        @include mixins.svg-stroke(bootstrap.theme-color());
    }

    .svg-fg {
        @include mixins.svg-fill(colors.$white);
    }

    .svg-bg {
        @include mixins.svg-fill(bootstrap.theme-color());
    }


    // Defaults for multi-color themeable icons
    .svg-primary {
        @include mixins.svg-fill(bootstrap.theme-color());
        @each $tint, $percentage in colors.$color-tints {
            &-#{$tint} {
                @include mixins.svg-fill(bootstrap.theme-color(), bootstrap.theme-color(), $percentage);
            }
        }
    }

    .svg-secondary {
        @include mixins.svg-fill(bootstrap.theme-color('secondary'));

        @each $tint, $percentage in colors.$color-tints {
            &-#{$tint} {
                @include mixins.svg-fill(bootstrap.theme-color('secondary'), bootstrap.theme-color('secondary'), $percentage);
            }
        }
    }

    .svg-dark {
        @include mixins.svg-fill(bootstrap.theme-color('dark'));

        @each $tint, $percentage in colors.$color-tints {
            &-#{$tint} {
                @include mixins.svg-fill(bootstrap.theme-color('dark'), bootstrap.theme-color('dark'), $percentage);
            }
        }
    }

    .svg-brand {
        @include mixins.svg-fill(functions.brand-color());
        @each $tint, $percentage in colors.$color-tints {
            &-#{$tint} {
                @include mixins.svg-fill(functions.brand-color(), bootstrap.theme-color(), $percentage);
            }
        }
    }