@use 'abstracts/colors';
@use 'vendors/bootstrap';


// Component HTML does not have a unique selector. Therefore, a form anscestor selector must be used in production.
.EPiServerForms button[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
    & img {
        max-height: 1rem;
    }
}