@use 'abstracts/colors';
@use 'vendors/bootstrap';

img {
    max-width: 100%;
}

.rounded {
    @include bootstrap.border-radius(bootstrap.$border-radius);
}

@include bootstrap.media-breakpoint-down(md){
    .img-fluid {
        width: 100%;
    }
}