@use 'abstracts/colors';
@use 'vendors/bootstrap';

.FormRange {

    color: bootstrap.theme-color();

    & > span {
        display: flex;


        & input {
            margin: 0 map-get(bootstrap.$spacers, 1);
            box-shadow: none;
            border: none;
        }
    }
}