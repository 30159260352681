@use 'abstracts/colors';
@use 'vendors/bootstrap';

.FormCaptcha {
    & .FormCaptcha__Refresh, & .FormCaptcha__Input {
        display: block;
    }


    & .FormCaptcha__Image {
        max-height: 5rem;
    }
}

.FormCaptcha__Refresh {
    @extend .btn;
    @extend .btn-primary;
}