@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'base/layout';
@use 'vendors/bootstrap';   


/*
    Emergency Menu
*/ 


// Mobile
@include bootstrap.media-breakpoint-down(sm) {

    // Main button
    #emergency-dropdown {
        color: functions.brand-color('alert');
        background-color: functions.tint(functions.brand-color('alert'), 97);

        &.nav-link.dropdown-toggle:focus::after,
        &.nav-link.dropdown-toggle:hover::after,
        &.nav-link.dropdown-toggle::after {
            border-color: functions.brand-color('alert');
        }
    }

    // Links/content
    #nav-emergency {

        // Show descriptions in Emerge menu
        .nav-desc {
            display: block;
            margin-bottom: bootstrap.$nav-link-padding-y;
            padding: 0 bootstrap.$nav-link-padding-x*2;
            font-size: bootstrap.$font-size-sm;
            line-height: bootstrap.$line-height-sm;
        }

        // And make these look more like links
        .navbar-nav .nav-link {
            text-decoration: bootstrap.$link-decoration;
        }

        .nav-content {
            display: flex;
            flex-direction: column;
            background-color: functions.tint(functions.brand-color(), 95);
            padding: bootstrap.$spacer*0.5 bootstrap.$spacer*0.5 0 bootstrap.$spacer*0.5;

            p {
                margin-bottom: bootstrap.$spacer*0.25;
            }
        
            .h1, .h2, .h3, .h4, .h5, .h6 {
                display: none;
            }

            .external-link::after {
                right: auto;
            }
        }
    }

} // end: Mobile


// Tablet+
@include bootstrap.media-breakpoint-up(md){

    // Journey Companion, Emergency, and Support SK top level buttons should look about the same
    #emergency-dropdown {

        @include mixins.header-rounded-button(
            $color: functions.brand-color('alert'), 
            $bg-color: functions.tint(functions.brand-color('alert'), 97),
            $border-color: functions.tint(functions.brand-color('alert'), 70)
        );
        border-width: bootstrap.$border-width bootstrap.$border-width bootstrap.$border-width 0;
        @include bootstrap.border-right-radius(bootstrap.$btn-border-radius);
        font-size: bootstrap.$font-size-sm;

        .nav-icon {
            margin-left: bootstrap.$nav-link-padding-x*1.5*0.5;
        }

        &:hover,
        &:focus,
        &[aria-expanded="true"] {
            border-color: functions.brand-color('alert');
        }
    }

    #nav-emergency {
        position: relative;

        & > .dropdown-menu {
            width: 100vw;
        }

        .nav-pages {
            flex: 3 1 0%;
            padding-right: bootstrap.$spacer;
        }

        .nav-content {
            flex: 2 1 0%;
            padding-top: 2.5rem; // $dropdown-title-icon-size-desktop
        }

        .nav-pages .dropdown-title {
            color: functions.brand-color('alert');
        }

        .nav-desc {
            display: block;
        }
    }

}


@include bootstrap.media-breakpoint-up(lg) {

    #nav-emergency > .dropdown-menu {
        width: 80vw;
    }

    #nav-emergency .nav-content {
        margin-left: map-get(bootstrap.$spacers, 2);
    }

 }


@include bootstrap.media-breakpoint-up(xl) {

    #nav-emergency > .dropdown-menu {
        width: 50rem;
        margin-top: .5rem;
    }

}