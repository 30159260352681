@use "abstracts/colors";
@use "abstracts/functions";
@use "abstracts/mixins";
@use "vendors/bootstrap";

.card-collapse {
    @include mixins.box-shadow-hover(bootstrap.$box-shadow);

    .card-header {
        padding: .25rem;
        background-color: colors.$white;
    }

    .card-body {
        margin-bottom: 0;
        padding: map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 3) map-get(bootstrap.$spacers, 2) map-get(bootstrap.$spacers, 3);
    }
}


.btn.collapse-trigger {
    width: 100%;
    max-width: none;
    padding: map-get(bootstrap.$spacers, 2) 1.25*bootstrap.$spacer map-get(bootstrap.$spacers, 2) map-get(bootstrap.$spacers, 2);
    background-color: colors.$white;
    font-size: bootstrap.$font-size-base;
    font-weight: bootstrap.$font-weight-bold;
    text-align: left;
    text-decoration: none;
    outline: none;
    @include bootstrap.border-radius(bootstrap.$card-border-radius);

    &:active,
    &:active:hover:focus {
        color: bootstrap.theme-color();
        background-color: functions.theme-color-tint('primary','15');    
    }

    @include mixins.collapse-icon($right: map-get(bootstrap.$spacers, 2));
}


/* Extra styles for non-JavaScript fall-back */

.no-js {
    .collapse {
        display: block;
    }

    .collapse-trigger::after {
        display: none;
    }
}