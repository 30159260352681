@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


/* 
    External link anchors/buttons
*/

    .external-link {
    //    position: relative;
    }

    .external-link:after {
        content: ""/"External link.";
        display: inline-block;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: top right;
        margin-left: bootstrap.$btn-padding-y*0.5;
        width: variables.$external-link-icon-size;
        height: variables.$external-link-icon-size;
        background-image: functions.get-svg('external-link', bootstrap.theme-color());
    }

    @each $color, $value in bootstrap.$theme-colors {
        .btn.btn-#{$color}.external-link::after {
            background-image: functions.get-svg('external-link', colors.$white);
        }

        .btn.btn-#{$color}.external-link:focus::after,
        .btn.btn-#{$color}.external-link:hover::after,
        .btn.btn-outline-#{$color}.external-link::after {
            background-image: functions.get-svg('external-link', $value);
        }
    }


/* 
    Project-wide extra styles for base buttons
*/

    .btn {
        display: block;
        width: 80%;
        max-width: 25rem;
        margin: 0 auto;

        @include bootstrap.media-breakpoint-up(md) {
            display: inline-block;
            width: auto;
            max-width: none;
            margin: 0;
        }

        & > svg {
            width: variables.$btn-icon-size;
            height: variables.$btn-icon-size;
        }
    }

    .btn:focus {
        border-width: bootstrap.$border-width;
    }
    
    // Add margin between primary and outline-primary
    @include bootstrap.media-breakpoint-down(sm){
        .btn-primary + .btn,
        .btn-outline-primary + .btn {
            margin-top: map-get(bootstrap.$spacers, 1);
        }
    }
    @include bootstrap.media-breakpoint-up(md){
        .btn-primary + .btn,
        .btn-outline-primary + .btn {
            margin-left: map-get(bootstrap.$spacers, 1);
        }
    }

    // Customizations for the Primary button colors to meet styleguide, in addition to 
    // the custom mixins used for all buttons in /src/sass/vendors/_bootstrap-mixins.scss.
    // Also related mixin.btn-brand()
    .btn-primary:not(:disabled):not(.disabled) {

        &:hover,
        &.hover {
            border-color: bootstrap.theme-color('secondary');
        }

        &.active {
            @include bootstrap.hover(){
                border-color: bootstrap.theme-color('secondary');
            }
        }

        & > svg {
            @include mixins.svg-fill(colors.$white);
        }

        &.focus,
        &.active {
            & > svg {
                @include mixins.svg-fill(bootstrap.theme-color());
            }
        }

        @include bootstrap.hover-focus-active(){
            & > svg {
                @include mixins.svg-fill(bootstrap.theme-color());
            }
        }
    }

    // Also related mixin.btn-outline-brand()
    .btn-outline-primary:not(:disabled):not(.disabled) {

        &:hover,
        &.hover {
            border-color: bootstrap.theme-color('secondary');
        }

        &:focus,
        &.focus,
        &.active:focus {
            background-color: functions.theme-color-tint('secondary', '15');
            border-color: bootstrap.theme-color('secondary');
        }
    }


/*
    Unique Button Variations
*/

    // "Page" / "Filter" buttons from Styleguide
    .btn.btn-link {

        @include bootstrap.hover-focus(){
            color: bootstrap.theme-color();
            background-color: colors.$white;
            border-color: bootstrap.theme-color('secondary');
        }

        &:active,
        &.active {
            color: colors.$white;
            background-color: bootstrap.theme-color();

            @include bootstrap.hover-focus(){
                color: bootstrap.theme-color();
                background-color: colors.$white;
            }
        }

        &:active:hover:focus {
            border-color: bootstrap.theme-color();
            color: colors.$white;
            background-color: bootstrap.theme-color();
        }
    }

    button.btn-link {
        text-decoration: none;

        @include bootstrap.hover-focus(){
            text-decoration: none;
        }
    }


    // Brand Main button 
    // - Not currently used...
    //.btn-brand {
    //    @include mixins.btn-brand();
    //}

    //.btn-outline-brand {
    //    @include mixins.btn-outline-brand();
    //}


    // Icon Buttons
    // - Base styles for most "svg circle icons"
    .btn-icon {
        font-size: bootstrap.$font-size-base;
        width: auto;
        max-width: none;
        padding: 0;

        & > svg, & > img {
            width: variables.$btn-icon-size-xl;
            height: variables.$btn-icon-size-xl;
        }
    }


    // Arrow Buttons
    // - Used for "Arrow" buttons from Styleguide, and others
    // - Is effectively a round primary button with some hover/focus colors
    // - Used for a few "round primary" buttons
    .btn-arrow {
        @include mixins.btn-icon();
    }


    // Circle button 
    // - Used for "Page" buttons from Styleguide, and others
    // - tries to size like content icons
    // - use the inner span for size consistency with varied content, i.e. page numbers 
    .btn-circle {
        padding: variables.$btn-circle-padding;
        line-height: 1;
        display: inline-flex;
        width: auto;
        max-width: none;

        & > span {
            display: inline-block;
            min-width: variables.$btn-icon-size;
            min-height: variables.$btn-icon-size;
            line-height: 1;
        }
    }


    // Square button
    // - Used for "Filter" buttons from Styleguide, and others
    // - Reduces corner radius
    // - Regular/auto width in mobile
    .btn-square {
        width: auto;
        max-width: none;
        @include bootstrap.border-radius(bootstrap.$border-radius-sm);
    }