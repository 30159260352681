@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


/* Utilities */

    @mixin ie11() {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
      }
    }

    // Edge 12-18 (Not Chromium)
    @mixin oldEdge() {
        @supports (-ms-ime-align:auto) { 
            @content;
        }
    }

    // This is the utility to hook into the object-fit polyfill for IE11!
    @mixin object-fit($fit: fill, $position: null){
      -o-object-fit: $fit;
         object-fit: $fit;
      @if $position {
	      -o-object-position: $position;
	         object-position: $position;
  	    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
      } @else {
  	    font-family: 'object-fit: #{$fit}';
      }
    }


/* Common styles */

     // Hovers

     @mixin box-shadow-xl($color: bootstrap.theme-color()){

        $box-shadow:    0 0.5rem 0.1rem -0.6rem rgba($color, 0.05), 
                        0 0.75rem 0.25rem -0.6rem rgba($color, 0.07), 
                        0 1rem 0.65rem -0.6rem rgba($color, 0.08), 
                        0 1.5rem 1rem -0.6rem rgba($color, 0.09), 
                        0 1.5rem 2rem -0.6rem rgba($color, 0.05), 
                        0 1.5rem 3rem -0.6rem rgba($color, 0.05);

        @include bootstrap.box-shadow($box-shadow);
     }

    // Add a box-shadow on hover, useful for passing box shadows larger than bootstraps
    @mixin box-shadow-hover($box-shadow){
        &:hover {
            @include bootstrap.box-shadow($box-shadow);
        }
    }


    // SVGs

    @mixin svg-fill($fill-color, $bg-color: transparent, $percentage: 0%) {
        fill: functions.tint($fill-color, $percentage);
        @if $bg-color == transparent {
            background-color: $bg-color;
        } @else {
            background-color: functions.tint($bg-color, $percentage);
        }
    }

    @mixin svg-stroke($stroke-color, $bg-color: transparent, $percentage: 0%) {
        stroke: functions.tint($stroke-color, $percentage);
        @if $bg-color == transparent {
            background-color: $bg-color;
        } @else {
            background-color: functions.tint($bg-color, $percentage);
        }
    }


/* Buttons */

    // This should call the bootstrap mixin override AND
    // should use/mirror whatever is in buttons.scss for .btn-primary
    @mixin btn-brand(){

        @include bootstrap.button-variant(functions.brand-color(), functions.brand-color(), $hover-border: functions.brand-color(), $active-border: functions.brand-color());

        &:not(:disabled):not(.disabled) {
            @include bootstrap.hover(){
                border-color: functions.brand-color();
            }

            &.active {
                @include bootstrap.hover(){
                    border-color: functions.brand-color();
                }
            }
        }

        &.external-link {

            &::after {
                background-image: functions.get-svg('external-link', colors.$white);
            }

            @include bootstrap.hover-focus(){
                &::after {
                    background-image: functions.get-svg('external-link', functions.brand-color());
                }
            } 
        }
    }

    // This should call the bootstrap mixin override AND
    // should use/mirror whatever is in buttons.scss for .btn-outline-primary
    @mixin btn-outline-brand(){

        @include bootstrap.button-outline-variant(functions.brand-color(), functions.brand-color());

        &:hover {
            border-color: map-get(colors.$brand-colors, 'accent');
        }

        &:focus,
        &.active:focus {
            background-color: functions.tint(map-get(colors.$brand-colors, 'accent'), 85);
            border-color: map-get(colors.$brand-colors, 'accent');
        }

        // External link...
    }

    // Used to style two-tone themeable icons (see also: buttons.scss)
    @mixin btn-icon($fg: colors.$white, $bg: bootstrap.theme-color(), $hover-fg: $fg, $hover-bg: bootstrap.theme-color('secondary'), $active-fg: $fg, $active-bg: functions.theme-color-tint('primary', '60')){
        
        background-color: $bg;
        transition: 0s;

        .svg-fg {
            @include svg-fill($fg);
        }

        .svg-bg {
            @include svg-fill($bg);
        }

        @include bootstrap.hover-focus(){
            background-color: $hover-bg;

            .svg-fg {
                @include svg-fill($hover-fg);
            }
            .svg-bg {
                @include svg-fill($hover-bg);
            }
        }

        &:active,
        &:hover:active {

            background-color: $active-bg;

            .svg-fg {
                @include svg-fill($active-fg);
            }

            .svg-bg {
                @include svg-fill($active-bg);
            }
        }
    }

    // Lighter variant, for social icons etc
    @mixin btn-icon-accent(){
        @include btn-icon(
            $fg: bootstrap.theme-color(), 
            $bg: functions.theme-color-tint('secondary','15'), 
            $hover-fg: bootstrap.theme-color(), 
            $hover-bg: functions.theme-color-tint('secondary','15'),
            $active-fg: colors.$white, 
            $active-bg: bootstrap.theme-color()
        );
    }

    // On brand colors, for footer/header etc
    @mixin btn-icon-brand(){
        @include btn-icon(
            $fg: colors.$white, 
            $bg: functions.brand-color(),
            $hover-fg: colors.$white, 
            $hover-bg: functions.brand-color('accent'),
            $active-fg: colors.$white, 
            $active-bg: functions.tint(functions.brand-color(), 40)
        );
    }


    @mixin collapse-icon($color: functions.theme-color-tint('primary', '60'), $hover-color: bootstrap.theme-color(), $top: null, $right: bootstrap.$card-spacer-x, $size: variables.$dropdown-chevron-size){

        position: relative; 

        &::after {
            position: absolute;
            @if $top!=null {
                top: $top;
            } @else {
                top: calc(50% - #{$size*0.5 - bootstrap.$border-width});
            }
            right: $right;
            display: inline-block;
            content: "";
            width: $size;
            height: $size;
            border: solid $color;
            border-width: 0 bootstrap.$border-width bootstrap.$border-width 0;
            pointer-events: none;
            transform: translateY(-50%) rotate(45deg);
        }

        &:focus::after,
        &:hover::after {
            border-color: $hover-color;
        }

        &[aria-expanded="true"]::after {
            transform: translateY(0%) rotate(-135deg);
        }
    }


    @mixin header-rounded-button($color: functions.brand-color(), $bg-color: functions.tint(functions.brand-color(), 95), $border-color: functions.tint(functions.brand-color(), 70)){

        padding: bootstrap.$nav-link-padding-y bootstrap.$nav-link-padding-x*1.5;
        line-height: bootstrap.$line-height-base;
        color: $color;
        background-color: $bg-color;
        border-color: $border-color;
        border-style: solid;

        &:hover,
        &:focus {
            text-decoration: bootstrap.$link-decoration;
        }

        .nav-icon {
            vertical-align: middle;
        }
    }