@use 'abstracts/colors';
@use 'abstracts/mixins';
@use 'vendors/bootstrap';


.card-image {
    flex-direction: row;
    flex-wrap: wrap;
    background-color: transparent;
    @include bootstrap.box-shadow(none);
    @include mixins.box-shadow-hover(none);

    .card-mobile-buttons {
        display: none;
    }
}

.card-image > .card-layout-wrap {

    // Style inner wrapper to look like card
    @include bootstrap.box-shadow(bootstrap.$box-shadow);
    background-color: bootstrap.$card-bg;
    background-clip: border-box;
    border: bootstrap.$card-border-width solid bootstrap.$card-border-color;
    @include bootstrap.border-radius(bootstrap.$card-border-radius);

    // Layout magic
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    width: 100%;

    .card-img-wrapper {
        width: 100%;
    }

    .card-img,
    .card-body {
        flex: none;
        width: 100%;
    }

    .card-img {
        @include mixins.object-fit(cover);
    }

    .card-body {
        margin-left: -100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: bootstrap.$card-spacer-x*0.5 bootstrap.$card-spacer-x;

        .stretched-link {
            align-self: flex-start;
        }
    }

    .card-title {
        flex-basis: 100%;
    }

    .card-buttons {
        display: flex;
        flex-basis: 100%;
        align-self: flex-end;
        align-items: flex-start;

        .btn {
            margin-bottom: 0;
        }
    }
}


@include bootstrap.media-breakpoint-down(sm){
    .card-image {

        & > .card-layout-wrap .card-buttons {
            display: none;
        }

        .card-mobile-buttons {
            display: block;
            width: 100%;
            padding-top: bootstrap.$card-spacer-y*0.5;
        }
    }
}