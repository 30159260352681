﻿@use 'abstracts/colors';
@use 'vendors/bootstrap';

/*apply the styles from Bootstrap to this control*/
.FormDateTime__Input {	
	@extend .form-control;
}

/*Datepicker puts the words prev and next in the datepicker.  This hides it.*/
.ui-datepicker .ui-icon {
	color: transparent;
}