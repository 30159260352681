﻿@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';

/*h1 a::before {
	display: block;
	content: " ";
	margin-top: -70px;
	height: 70px;
	visibility: hidden;
}*/

#PageNavSticky {
	position: fixed;
	top: 105px; //6.5em;
	right: 0px;
	width: 300px;
	height: 100%;
	padding-top: 20px;
	padding-bottom: 1em;
	background-color: #f7fbfe;
	text-align: left;
	border-left-color: #d9e8f3;
	border-left-style: solid;
	border-left-width: 2px;
	padding-left: .5em;
	padding-right: 1em;


	.stickyLinkContainer {
		padding-top: .5vh;
		padding-bottom: .5vh;
		margin-left: .5rem;
		padding-left: 5px;
		transition-duration: .2s;
		transition-property: border-left-color;
		border-left-color: none;
		border-top: 1px solid #8eb4d1;

		a {
			text-decoration: none;
		}

		a:hover {
			text-decoration: underline;
		}

		a:active {
			background-color: transparent;
		}

		a:focus {
			background-color: transparent;
		}
	}
}


.padTopForAlert {
	margin-top: 80px;
	transition: .1s linear all;
}


.nav-pop {
	width: calc(100% - 300px);
}

.stickySelected {
	border-left-width: 4px;
	border-left-style: solid;
	border-left-color: #0067b1;
	transition: .5s linear all;
}

@media (min-width: 48em) and (max-width: 74.98em) {
	/* Tablet */
	#PageNavSticky {
		top: 140px;
	}

	.padTopForAlert {
		margin-top: 90px;
	}

}

@media (max-width: 47.98em) {
	/* Mobile */
	#PageNavSticky {
		position: unset;
		display: block;
		width: unset;
		top: unset;
		right: unset;
		padding-bottom: 1em;
		text-align: left;
		border-left-color: unset;
		border-left-style: unset;
		border-left-width: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
		padding-bottom: 2rem;
		padding-top: 1em;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 2em;
		height: unset;
		max-width: 33.75em;
		box-shadow: 0 0.35rem 0.75rem rgb(0 103 177 / 13%), 0 2.5rem 1rem -2rem rgb(0 103 177 / 18%);


		.stickyLinkContainer {
			margin-top: 0;
			margin-left: 0.5rem;
			padding-left: 5px;
		}
	}


	.padTopForAlert {
		margin-top: 0;
		transition: .1s linear all;
	}

	.nav-pop {
		width: 100%;
	}


	.stickySelected {
		border-left: unset;
	}
}
