@use 'abstracts/colors';
@use 'abstracts/mixins';
@use 'vendors/bootstrap';

.jumbotron-layered {

    .jumbotron-body {
        z-index: 1;
        background-color: colors.$white;
        padding: bootstrap.$spacer;
        @include bootstrap.box-shadow(bootstrap.$box-shadow);
    }

    .jumbotron-img img {
        width: 100%;
    }

    @include bootstrap.media-breakpoint-up(md){
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;

        .jumbotron-body,
        .jumbotron-img {
            flex: none;
            width: 100%;
        }

        .jumbotron-body {
            width: 65%;
            margin-left: -82.5%;
        }
    }

    @include bootstrap.media-breakpoint-up(xl){
        .jumbotron-body {
            width: 50%;
            margin-left: -75%;
        }
    }

    @include bootstrap.media-breakpoint-down(sm){
        .jumbotron-body {
            text-align: center;
        }
    }
}