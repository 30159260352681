@use 'abstracts/colors';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use "vendors/bootstrap";


/*
    Base styles, spacing
*/

.media {
    background-color: colors.$white;
    padding: 0;
    @include bootstrap.border-radius(bootstrap.$card-border-radius);
    @include bootstrap.box-shadow(bootstrap.$box-shadow);

    .badge,
    .lead,
    .media-title {
        margin-top: 0;
        margin-bottom: map-get(bootstrap.$spacers, 2);
    }

    .rich-text {
        margin: 0;
    }

    .rich-text + .buttons {
        margin-top: map-get(bootstrap.$spacers, 2);
    }

    .media-body {
        text-align: left;
        padding: bootstrap.$card-spacer-x;

        .lead,
        .media-title {
            text-align: left;
        }

        .lead {
            font-size: bootstrap.$font-size-sm;
        }
    }

    .card {
        box-shadow: none;
    }
}
   
  

    // Image rounded borders
    // - Accepts an img.media-image or picture.media-image
    .media {

        .media-img,
        picture.media-img > img {
            @include bootstrap.border-top-radius(bootstrap.$card-inner-border-radius);

            @include bootstrap.media-breakpoint-up(lg) {
                @include bootstrap.border-top-radius(0);
                @include bootstrap.border-left-radius(bootstrap.$card-inner-border-radius);
            }
        }

        @include bootstrap.media-breakpoint-up(lg) {
            &:not(.image-padded).image-right {
                .media-img,
                picture.media-img > img {
                    @include bootstrap.border-left-radius(0);
                    @include bootstrap.border-right-radius(bootstrap.$card-inner-border-radius);
                }
            }
        }
    }

    // Image padded (for smaller images)
    .media.image-padded {

        padding: bootstrap.$card-spacer-x;
        justify-content: center;

        .media-img {
            @include bootstrap.border-radius(bootstrap.$border-radius-sm);
        }

        .media-body {
            flex: 1 1 auto;
            padding: bootstrap.$card-spacer-x 0 0 0;

            @include bootstrap.media-breakpoint-up(md) {
                flex: 1 1 0;
                padding: 0 0 0 bootstrap.$card-spacer-x;
            }
        }

        &.image-right .media-body {
            @include bootstrap.media-breakpoint-up(md) {
                padding: 0 bootstrap.$card-spacer-x 0 0;
            }
        }
    }


/* Layout */

    // Mobile vertical layout
    @include bootstrap.media-breakpoint-down(md){

        .media {
            flex-direction: column;

            &:not(.image-padded) {
                align-items: stretch;
            }

            .media-img-wrapper {
                /*flex: 0 0 auto;*/
                position: relative;

                .media-img {
                    width: 100%;
                }

                .badge {
                    position: absolute;
                    bottom: bootstrap.$card-spacer-x*0.5;
                    left: bootstrap.$card-spacer-x;
                }
            }

            .media-body {
                flex: 1 1 auto;
                align-self: stretch;

                .badge {
                    display: none;
                }
            }
        }
    }

    @include bootstrap.media-breakpoint-up(lg){

        .media {
            align-items: center;

            .media-img-wrapper .badge {
                display: none;
            }
        }
    }

    // Image on the right
    .media-img-right {
        display: none;
    }

    @include bootstrap.media-breakpoint-up(md) {

        .media.image-padded.image-right {

            .media-img-left {
                display: none;
            }

            .media-img-right {
                display: block;
            }
        }
    }

    @include bootstrap.media-breakpoint-up(lg) {


        .media:not(.image-padded).image-right {

            .media-img-left {
                display: none;
            }

            .media-img-right {
                display: block;
            }
        }
    }

    // Image padded (for smaller images)
    .media.image-padded {

        .media-img-wrapper {
            align-self: stretch;

            .media-img {
                width: auto;
                height: auto;
            }
        }

        @include bootstrap.media-breakpoint-up(md){
            flex-direction: row;
        }
    }

    
