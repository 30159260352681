@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

$results-spacer-y: map-get(bootstrap.$spacers, 2);
$results-spacer-y-lg: $results-spacer-y*2;

.search-results {
    margin-bottom: $results-spacer-y;

    .question-form {
        margin-bottom: $results-spacer-y-lg;
    }

    .facets {
        margin-bottom: $results-spacer-y;
    }

    select.form-control {
        margin-left: map-get(bootstrap.$spacers, 1);
        width: auto;
        height: 2.2em;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none;
        cursor: pointer;
        text-decoration: underline;
    }
}

.results-tools {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: bootstrap.theme-color('dark');
}

.results-per-page,
.results-filter,
.results-sort-by {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    & > label {
        margin: 0;
        font-size: bootstrap.$font-size-sm;

        &:after {
            content: ":";
        }
    }
}

#update-results-mobile {
    display: none;
}

.update-results {
    font-size: bootstrap.$font-size-sm;
    line-height: 1;
}

@include bootstrap.media-breakpoint-down(sm){

    .results-per-page,
    .results-filter,
    .results-sort-by {
        margin-bottom: $results-spacer-y*0.5;
        flex-basis: 100%;
        justify-content: center;
    }

    .results-per-page,
    .results-sort-by {
        margin-bottom: $results-spacer-y*0.5;
    }

    .results-filter {
        flex-wrap: wrap;
        margin-bottom: $results-spacer-y-lg*1.5;

    }

    .results-title {
        text-align: center;
    }

    #update-results-mobile {
        display: block;
        margin-top: $results-spacer-y*0.5;
    }

    #update-results {
        display: none;
    }

}


@include bootstrap.media-breakpoint-up(md){

    .results-tools {
        margin-bottom: $results-spacer-y-lg;
    }

    .results-filter {
        padding: 0;
    }

    .results-per-page,
    .results-sort-by {
        padding: 0 map-get(bootstrap.$spacers, 2) 0 0;
    }

    #update-results {
        margin-left: map-get(bootstrap.$spacers, 2);
    }

    .title-and-sort {
        flex-wrap: nowrap;
        align-items: flex-end;
        justify-content: space-between;

        .results-title {
            flex-shrink: 1;
            margin-bottom: 0;
        }
    }
}