@use 'abstracts/colors';
@use './_bootstrap-variables.scss' as bootstrap;


/*
    We override alerts to provide contrast correction
    for the text and background 
*/

    @mixin alert-variant($background, $border, $color, $percentage: 97) {
      color: $color;
      background-color: mix(colors.$white, $color, $percentage);
      border-color: mix(colors.$white, $color, $percentage);
      border-radius: bootstrap.$border-radius-sm;
      padding: bootstrap.$spacer*.25;

      hr {
        margin: bootstrap.$spacer*.25 0;
        background-color: mix(colors.$white, $color, 80);
      }

      .alert-link {
        color: darken($color, 10%);
      }
    }



/*
    We override badges to provide contrast correction by
    using thick borders with regular body text. 

    See also: /src/features/badge
*/

    @mixin badge-variant($bg) {
        color: map-get(colors.$brand-colors, 'subtle');
        background-color: colors.$white;
        border: solid .25rem $bg;

        @at-root a#{&} {

            @include hover-focus() {
                color: map-get(colors.$brand-colors, 'subtle');
                background-color: colors.$white;
                border: solid .25rem $bg;
            }

            &:focus,
            &.focus {
                outline: 0;
                box-shadow: 0 0 0 $badge-focus-width rgba($bg, .5);
            }
        }
    }


/*
    We override button mixins to follow the unique look & feel
    from styleguide, without massive overrides to CSS (reducing code).

    These have been kept as close as possible by changing the method 
    definition when possible, but some color/focus/borders simply had to 
    be changed. USE A DIFF TOOL before updating, https://quickdiff.net/.

    See also: /src/features/buttons
    Note: Primary buttons have unique border hover color in /src/features/buttons
    Note: this is not the entire set of mixins from bootstrap/scss/mixins/_buttons.scss
*/

    @mixin button-variant($background, $border, $hover-background: colors.$white, $hover-border: $border, $active-background: $background, $active-border: transparent) {

        color: color-yiq($background);
        @include gradient-bg($background);
        border-color: $border;
        @include box-shadow($btn-box-shadow);

        &:hover,
        &.hover {
            color: $background;
            @include gradient-bg($hover-background);
            border-color: $hover-border;
        }

        &:focus,
        &.focus {
            color: $background;
            @include gradient-bg($hover-background);
            border-color: $hover-border;
            box-shadow: none;
        }
        // Disabled comes first so active can properly restyle
        &.disabled,
        &:disabled {
            color: color-yiq($background);
            background-color: $background;
            border-color: $border;
            // Remove CSS gradients if they're enabled
            @if $enable-gradients {
                background-image: none;
            }
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
            color: $background;
            background-color: mix(colors.$white, $active-background, map-get(colors.$color-tints, '15'));

            @if $enable-gradients {
                background-image: none; // Remove the gradient for the pressed/active state
            }

            border-color: $active-border;
            box-shadow: none;

            &:hover,
            &.hover {
                border-color: $hover-border;
                @include gradient-bg($hover-background);
            }

            &:focus {
                @include gradient-bg($hover-background);
                border-color: $background;
                box-shadow: none;
            }
        }

        &:not(:disabled):not(.disabled):active:hover:focus {
            border-color: mix(colors.$white, $background, map-get(colors.$color-tints, '15'));
            background-color: mix(colors.$white, $background, map-get(colors.$color-tints, '15'));
        }
    }

    @mixin button-outline-variant($color, $color-hover: $color, $active-background: colors.$white) {
        color: $color;
        @include gradient-bg($active-background);
        border-color: $color;

        &:hover,
        &.hover {
            color: $color-hover;
            background-color: $active-background;
            border-color: mix(colors.$white, $color, map-get(colors.$color-tints, '30'));
        }

        &:focus,
        &.focus {
            background-color: mix(colors.$white, $color, map-get(colors.$color-tints, '15'));
            box-shadow: none;   
            &:hover,
            &.hover {
                background-color: $active-background;
            }
        }

        &.disabled,
        &:disabled {
            color: $color;
            background-color: transparent;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
            color: $color-hover;
            background-color: $active-background;
            border-color: mix(colors.$white, $color, map-get(colors.$color-tints, '30'));

            &:focus {
                background-color: mix(colors.$white, $color, map-get(colors.$color-tints, '15'));
                border-color: $color;
                box-shadow: none;
            }
        }

        &:not(:disabled):not(.disabled):focus:hover {
            background-color: $active-background;
        }

        &:not(:disabled):not(.disabled):active:hover:focus{
            background-color: colors.$white;
            border-color: mix(colors.$white, $color, map-get(colors.$color-tints, '30'));
        }
    }