@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';


/**

    The site header is a very complicated component in terms of styling.
    
    There are both behavioural and styling difference between mobile vs tablet & desktop.

    Traditional 'Mobile First' CSS would therefore require significant overrides.
    
    Instead we opt for using explicit mobile styles and explicit tablet+ styles.
    
    Furthermore, the navigation has a number of areas that are also unique and complex, so
    we also break out the unique submenus (emergency, journey-companion, support-sk). Yes this
    adds some complexity to the file structure, but the intention is to improve code maintenance.

    We try and do a few things:
    
        - Flatten selectors when possible, i.e. .nav-link, not: .navbar-nav > .nav-item > .nav-link.
        - As mentioned, use any viewport queries necessary to avoid inheritence-hell!
        - In some cases, small duplication is better than complex inheritence.
        - As mentioned, we break this up into a few files. (More windows but less scrolling!)
        - Any time something causes regressions elsewhere, consider moving/altering the code. (KISS)

*/


// Base/global styles for <header>, all viewports 

#site-header {

    width: 100%;
    top: 0;
    left: 0;
    z-index: bootstrap.$zindex-sticky + 1;
    background-color: colors.$white;

    // Force on-brand colors throughout header

        .h1, .h2, .h3, .h4, .h5, .h6 {
            color: functions.brand-color();
        }

        .dropdown a:not(.btn) {
            color: functions.brand-color();
        }

        .dropdown .external-link:after {
            //background-image: functions.get-svg('external-link', functions.brand-color());
        }

        a:not(.btn):focus {
            background-color: functions.tint(functions.brand-color(), 85);
        }
}

    // General links/button styles

        .nav-link { // Be very careful what you change/add here
            text-align: left;
            font-size: bootstrap.$font-size-base;
            line-height: bootstrap.$line-height-sm;

            &:focus { 
                outline: 0;
            }
        }

        // Let long link names wrap
        .dropdown-menu .dropdown-toggle {
            white-space: normal;
        }


/* 
    Mobile Menu
*/

@include bootstrap.media-breakpoint-down(sm) {

    $navbar-brand-height: 2.5rem; // Important: This is controlled in CSS for #site-navigation .nav-layout-wrap height calculation
    $spacer-y: bootstrap.$nav-link-padding-y*2; // We double $nav-link-padding-x and -y in mobile manually.
    $spacer-x: bootstrap.$nav-link-padding-x*2;

    // Un-expanded top <header>

        #site-header {

            width: 100%;

            .navbar-brand {
                margin: $spacer-x; // let the logo dictate the height

                & > img {
                    height: $navbar-brand-height; // Important: This is controlled in CSS for #site-navigation .nav-layout-wrap height calculation
                }
            }

            .navbar-toggler {
                margin: 0 $spacer-x 0 auto; // auto for ie11
                border-color: transparent;
                font-size: bootstrap.$font-size-base*1.325;
                @include bootstrap.transition();

                .navbar-toggler-icon {
                    background-image: functions.get-svg('bootstrap-navbar-toggler', functions.brand-color());
                }

                &:focus {
                    border-color: functions.brand-color();
                }

                &:not(.collapsed),
                &:active {
                    background-color: functions.brand-color();

                    .navbar-toggler-icon {
                        background-image: functions.get-svg('bootstrap-navbar-toggler', colors.$white);
                    }
                }
            }
        }


    // Layout of expanded menu
    // - See also header.js

        #site-navigation {

            // This extra wrapper is required for showing/hiding to not interfere with flex, and for open/close transitions
            .nav-layout-wrap {
                height: calc(100vh - #{$navbar-brand-height + $spacer-x*2}); // Setting height is critical for overflow scroll.
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                background-color: functions.tint(functions.brand-color(), 85);
            }

            #navbar-brand-tablet, 
            #navbar-brand-desktop, 
            #nav-search, 
            #lang-select,
            .break {
                display: none;
            }

            .dropdown-menu,
            .nav-pages {
                width: 100%;
            }
        }


    // Mobile search form

        #nav-search-mobile { 
            padding: $spacer-y $spacer-x 0 $spacer-x;

            .input-wrap {
                $box-shadow: 0 .5rem 1rem rgba(functions.brand-color(), .15);
                @include bootstrap.box-shadow($box-shadow);
                &:hover {
                     @include mixins.box-shadow-xl(functions.brand-color());
                }
            }

            &.focus .input-wrap {
                border-color: functions.brand-color();
            }
        }

        #nav-search-submit  {
            @include mixins.btn-icon-brand();
        }  


    // Language Select

        #lang-select-mobile {
            width: 100%;
            padding: $spacer-y $spacer-x;
            color: functions.brand-color();
            background-color: functions.tint(functions.brand-color('subtle'), 95);
            border-radius: 0;
            font-size: bootstrap.$font-size-base;
            text-decoration: none;
            border: none;
        }


    // MyChart link

        #nav-mychart {
            color: functions.brand-color();
            background-color: functions.tint(functions.brand-color('subtle'), 95);

            &:after {
                background-image: functions.get-svg('external-link', functions.brand-color());
            }
        }


    // Hide content/icons in all child mobile menus

        .nav-content,
        .dropdown-title,
        .dropdown-desc,
        .dropdown-submenu-close,
        .nav-desc, 
        .nav-icon {
            display: none;
        }


    // General links/button styles

        #site-navigation .nav-link {
            width: 100%;
            border: none;
            padding: $spacer-y $spacer-x;

            &.external-link {
                padding-right: $spacer-x*2;

                &::after {
                    /*position: absolute;*/
                    bottom: 1em;
                    right: auto;
                }
            }

            &.dropdown-toggle {
                padding-right: $spacer-x*2;
                @include mixins.collapse-icon($color: functions.brand-color(), $hover-color: functions.brand-color(), $right: $spacer-x);
            }

            & > svg, & > img {
                display: none;
            }
        }

} // end: Mobile Menu


/* 
    Tablet & Destkop 
   - Reminder: if it doesn't apply to the top level header or all the dropdowns, it shouldn't be in here.
   - Reminder: being explicit with / duplication between breakpoints to avoid complicated inheritence is ok in this case.
*/

    $menu-spacer: map-get(bootstrap.$spacers, 2);
    $nav-icon-size: 1.25rem;
    $dropdown-title-icon-size-tablet: 1.75rem;
    $dropdown-title-line-height-desktop: 1.8;
    $dropdown-title-icon-size-desktop: 2.5rem; // see also nav-emergency
    $dropdown-title-icon-gutter-tablet: $dropdown-title-icon-size-tablet + bootstrap.$navbar-nav-link-padding-x;
    $dropdown-title-icon-gutter-desktop: $dropdown-title-icon-size-desktop + bootstrap.$navbar-nav-link-padding-x;
    $nav-link-chevron-padding: variables.$dropdown-chevron-size + bootstrap.$navbar-nav-link-padding-x;

// Tablet & Up
@include bootstrap.media-breakpoint-up(md) {

    // Header Layout 

        .navbar-brand,
        #nav-search-mobile,
        #nav-mychart,
        #site-header #lang-select-mobile {
            display: none;
        }

        .nav-layout-wrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
        }

        // Search & Lang Button
        #site-header .btn.btn-link.btn-circle {
            flex: 0 0 auto;
            align-self: center;
            
            width: 2.125rem;
            height: 2.125rem;
            padding: .125rem;
            line-height: 1;
            color: functions.brand-color();

            display: inline-flex;
            justify-content: center;
            align-items: center;

            @include bootstrap.hover-focus-active(){
                border-color: functions.brand-color();
            }

            & > svg, & > img {
                width: variables.$btn-icon-size-lg;
                height: variables.$btn-icon-size-lg;
            }

            .svg-fg {
                fill: functions.brand-color();
            }

            &:active {
                color: colors.$white;

                .svg-fg {
                    fill: colors.$white;
                }
            }
        }

        #nav-secondary {
            flex-grow: 1;
            flex-basis: auto;
        }


    // Dropdowns - General layout

        .dropdown {
            overflow: visible;
            position: initial;
        }

        #site-header .dropdown-menu {
            padding: $menu-spacer 0;
        }

        .dropdown-title, 
        .dropdown-desc {
            display: block;
        }

        .dropdown-title {
            margin: 0;

            .nav-icon {
                margin-right: bootstrap.$navbar-nav-link-padding-x;
                vertical-align: bottom;
            }
        }

        .dropdown-desc {
            margin: 0 0 $menu-spacer*1.5 0;
            line-height: bootstrap.$line-height-sm;
        }

        .dropdown-layout-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            overflow-y: auto;
            @include bootstrap.make-container();
        }

        .nav-column-wrap {
            display: flex;
            flex: 1 1 auto;
        }

        .nav-column-wrap .navbar-nav {
            flex-basis: 50%;

            &:only-child {
                flex-basis: 100%;
            }
        }


    // Content & Quick Links 

        .nav-content p {
            margin-bottom: bootstrap.$spacer*0.25;
        }


    // General links/button styles

        .nav-pages .navbar-nav {
            flex-direction: column;
        }

        .nav-item {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            
        }

        .dropdown-layout-wrap .nav-item {
            margin-bottom: $menu-spacer;
        }

        .dropdown-layout-wrap .nav-item .nav-link {
            flex: 1 1 0%;
            padding-right: $nav-link-chevron-padding*2;
            padding-bottom: bootstrap.$navbar-nav-link-padding-x*0.5;

            &.dropdown-toggle {
                @include mixins.collapse-icon($color: functions.brand-color(), $hover-color: functions.brand-color(), $top: 0.8125rem, $right: .625rem );
            }
        }

        .nav-link:not(.dropdown-toggle){
            text-decoration: bootstrap.$link-decoration;
        }

        .nav-icon {
            flex: 0 0 auto;
            width: $nav-icon-size;
            height: $nav-icon-size;
        }

        .dropdown-menu .nav-item .nav-icon {
            margin: bootstrap.$nav-link-padding-y bootstrap.$navbar-nav-link-padding-x*0.5 0 0;
        }

        .nav-desc {
            flex: 1 1 100%;
            margin: 0;
            padding: 0 $nav-link-chevron-padding*2 0 bootstrap.$navbar-nav-link-padding-x;
            font-size: bootstrap.$font-size-sm;
            line-height: bootstrap.$line-height-sm;
        }

        .nav-icon + .nav-link + .nav-desc {
            margin-left: $nav-icon-size + (bootstrap.$navbar-nav-link-padding-x*0.5);
        }
      
}
// end: Tablet & Up

@include bootstrap.media-breakpoint-down(sm) {
    #search-jc-wrap {
        display: none !important;
    }
}

    @include bootstrap.media-breakpoint-between(md, lg) {
        // Layout

        #navbar-brand-desktop {
            display: none;
        }
        // Center the logo
        // - Works in tablet
        // - Only out in desktop because of char differences in menu items
        #nav-emergency {
            display: flex;
            flex: 1 1 0%;
            justify-content: flex-start;
        }

        #navbar-brand-tablet {
            display: flex;
            justify-content: center;
            margin: bootstrap.$btn-padding-y*0.5 bootstrap.$btn-padding-x*0.5;
            padding: bootstrap.$btn-padding-y*0.5 bootstrap.$btn-padding-x*0.5;

            @include bootstrap.hover-focus-active() {
                border-color: functions.brand-color();
                background-color: colors.$white;
            }
        }

        #search-jc-wrap {
            display: flex;
            flex: 1 1 0%;
            justify-content: flex-end;
            align-items: center;
        }

        #nav-primary {
            flex-basis: 100%;
        }

        .nav-pages {
            flex-basis: 67%;
        }

        .nav-content {
            flex-basis: 30%;
        }
    }


    @include bootstrap.media-breakpoint-only(md) {
        // Layout

        .nav-desc {
            display: none;
        }
        // Dropdowns - General layout

        .dropdown-title .nav-icon {
            width: $dropdown-title-icon-size-tablet;
            height: $dropdown-title-icon-size-tablet;
        }

        .dropdown-close-icon {
            width: $dropdown-title-icon-size-desktop;
            height: $dropdown-title-icon-size-desktop;
        }

        .dropdown-desc {
            margin-left: $dropdown-title-icon-gutter-tablet;
        }

        .nav-pages .navbar-nav {
            margin-left: $dropdown-title-icon-gutter-tablet;

            & + .navbar-nav {
                margin-left: $dropdown-title-icon-gutter-tablet*0.5;
            }
        }
        // Gap out the text under the title given the smaller icon for this viewport
        .dropdown-menu .dropdown-title {
            margin-bottom: $menu-spacer*0.5;
        }
    }


    @include bootstrap.media-breakpoint-up(lg) {
        // Dropdowns - General layout

        .dropdown-title {
            line-height: $dropdown-title-line-height-desktop;

            .nav-icon {
                width: $dropdown-title-icon-size-desktop;
                height: $dropdown-title-icon-size-desktop;
            }
        }

        .btn-arrow > .dropdown-close-icon {
            width: $dropdown-title-icon-size-desktop;
            height: $dropdown-title-icon-size-desktop;
        }

        .dropdown-desc {
            margin-left: $dropdown-title-icon-gutter-desktop;
        }

        .nav-pages .navbar-nav {
            margin-left: $dropdown-title-icon-gutter-desktop;
        }
    }


    @include bootstrap.media-breakpoint-up(xl) {
        // Layout

        #navbar-brand-tablet {
            display: none;
        }

        #navbar-brand-desktop {
            padding: 0;
        }

        #nav-emergency {
            flex: 0 1 auto;
        }

        #nav-primary {
            flex: 1 1 0%;
        }

        #search-jc-wrap {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            flex: 0 1 auto;
        }

        #site-navigation .break {
            flex-basis: 100%;
            height: 0;
        }

        .nav-pages {
            flex-basis: 65%; // Add a little more space
        }

        .nav-content {
            flex-basis: 30%;
        }
    }
    /* 
    A little extra centering magic for big screens 
*/
    @media (min-width: 90em) {

        #nav-emergency {
            flex: 1 1 0%;
            justify-content: flex-start;
        }

        #search-jc-wrap {
            flex: 1 1 0%;
            justify-content: flex-end;
        }

        #nav-primary {
            display: flex;
            flex: 1 1 auto;

            & > .navbar-nav {
                margin: 0;
                max-width: none;
                width: 100%;

                & > .nav-item {
                    flex: 1 1 auto;
                }
            }
        }
    }
    /* Extra styles for non-JavaScript fall-back */

    .no-js #site-header {

        .nav-layout-wrap {
            align-items: stretch;
            flex-direction: column;
            height: auto;
        }

        #nav-search-mobile {
            display: block;
            padding: map-get(bootstrap.$spacers, 1);

            .form-group {
                margin-bottom: 0;
            }
        }

        #lang-select-mobile {
            display: block;
            text-align: center;
        }

        .navbar-brand,
        .navbar-toggler,
        #nav-emergency,
        #emergency-dropdown,
        #navbar-brand-tablet,
        #nav-primary,
        #search-jc-wrap,
        #nav-secondary,
        #nav-secondary-centering,
        #nav-support-sk,
        #nav-mychart {
            display: none;
        }
    }

    @include bootstrap.media-breakpoint-up(md) {

        .no-js #site-header {

            #nav-search-mobile {
                padding: .75*bootstrap.$spacer;
            }
        }
    }

    @include bootstrap.media-breakpoint-up(lg) {

        .no-js #site-header {

            .nav-layout-wrap {
                flex-direction: row;
                align-items: flex-start;
                flex-wrap: wrap;
            }

            #lang-select-mobile {
                padding: map-get(bootstrap.$spacers, 1);
                width: auto;
            }

            #nav-search-mobile {
                flex-basis: 33%;
            }

            #nav-emergency {
                display: flex;
                flex-basis: 66%;

                & > .dropdown-menu {
                    display: block;
                    width: auto;
                    position: static;
                    top: auto;
                    float: none;
                    box-shadow: none;
                }
            }
        }
    }
    //search flyout input top right navigation

    #site-header .search-box {
        width: fit-content;
        height: fit-content;
        position: relative;
        box-sizing: border-box;
    }

    #site-header .input-search {
        height: 50px;
        width: 50px;
        border-style: none;
        padding: 10px;
        font-size: 1rem;
        outline: none;
        border-radius: 25px;
        transition: all .5s ease-in-out;
        background-color: #fff;
        outline-color: functions.brand-color('main');
        padding-right: 40px;
        color: #595959;
        font-size: 1rem;
        box-sizing: border-box;
    }

    #site-header .input-search::placeholder {
        color: #595959;
    }

    #site-header .btn-search {
        width: 50px;
        height: 50px;
        border-style: none;
        font-size: 10px;
        font-weight: bold;
        outline: none;
        cursor: pointer;
        border-radius: 10%;
        position: absolute;
        right: 0px;
        color: #ffffff;
        background-color: transparent;
        pointer-events: painted;
    }

    #site-header .btn-search:focus ~ .input-search {
        width: 300px;
        background-color: transparent;
        border: solid 0.125rem white;
        transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
        border-radius: 50rem;
        box-shadow: 0 0.5rem 1rem rgb(0 103 177 / 15%);
    }

    #site-header .btn-search:hover path.svg-bg {
        fill: #00BEE1;
    }

    #site-header .input-search:focus {
        width: 300px;
        border-radius: 50rem;
        background-color: transparent;
        border: solid 0.125rem functions.brand-color();
        transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
        box-shadow: 0 0.5rem 1rem rgb(0 103 177 / 15%);
    }

    #site-header .form-group {
        margin-bottom: 0rem;
    }

    #site-header .fasearchblue {
        color: #0067b1;
    }

    #site-header::-webkit-validation-bubble-message {
        display: none
    }

    #site-header::-webkit-validation-bubble-arrow {
        display: none;
    }
