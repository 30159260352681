@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

.site-footer {

    // Colors in Header/Footer stay on brand
    color: functions.brand-color();

    @include bootstrap.media-breakpoint-down(xs){
        text-align: center;
    }

    a, 
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6
    {
        color: functions.brand-color();
    }

    a:not(.btn):focus {
        background-color: functions.tint(functions.brand-color(), 85);
    }

    .external-link:after {
        background-image: functions.get-svg('external-link', functions.brand-color());
    }

    .row,
    .btn-toolbar {
        justify-content: center;
    }

    @include bootstrap.media-breakpoint-up(sm){
        // Four col variant
        .btn-toolbar-social-small {
            justify-content: flex-start;
        }
    }

    // One col variant
    .btn-toolbar-social-large {
        margin-bottom: bootstrap.$spacer;
    }

    .footer-text {
        text-align: center;
        color: functions.brand-color('subtle');
        font-size: bootstrap.$font-size-sm;
    }

    // IE11 Back to Top extra space
    @include mixins.ie11(){
        padding-bottom: bootstrap.$spacer;
    }

    @supports not (position: sticky){
        padding-bottom: bootstrap.$spacer;
    }
}