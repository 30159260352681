@use 'abstracts/colors';
@use 'vendors/bootstrap';

.card-thin {
    flex-direction: row;
    align-items: center;
    padding: bootstrap.$card-spacer-y*0.5 bootstrap.$card-spacer-x*0.5;

    .card-img-wrapper {
        flex: 0 0 auto;
        width: 3.75rem;
    }

    .card-body {
        padding: 0;
    }

    .card-img-wrapper + .card-body,
    .card-body + .card-img-wrapper {
        margin-left: bootstrap.$card-spacer-x*0.5;
    }
}