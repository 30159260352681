@use 'abstracts/colors';
@use 'abstracts/variables';
@use 'vendors/bootstrap';

.FormChoice {
    & legend {
        display: inline-block;
        font-size: bootstrap.$font-size-base;
        font-weight: bootstrap.$font-weight-normal;
        line-height: variables.$line-height-md;
        text-align: left;
        color: bootstrap.theme-color();
    }



    & label {
        display: block;
    }


    
    & img {
        max-height: 2rem;
        margin-left: map-get(bootstrap.$spacers, 1);
    }
}