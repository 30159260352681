@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'vendors/bootstrap';

.card-topic {
    border-bottom-width: .25rem;

    .card-img-wrapper {
        padding: bootstrap.$card-spacer-y bootstrap.$card-spacer-x bootstrap.$card-spacer-y*0.5 bootstrap.$card-spacer-x;
    }

    .card-img-wrapper + .card-body {
        padding-top: 0;
    }

    .card-body {
        padding-bottom: bootstrap.$card-spacer-y*0.5;
    }

    & > :last-child {
        padding-bottom: bootstrap.$card-spacer-y;
    }
}

@include bootstrap.media-breakpoint-down(sm){

    .card-topic.hide-content-mobile {

        display: flex;
        align-items: center;
        flex-direction: row;
        text-align: left;
        border-bottom-width: .25rem;

        .card-body,
        .card-img-wrapper {
            padding: bootstrap.$card-spacer-y*0.5;

            & > img {
                max-width: 3rem;
                max-height: 3rem;
            }
        }

        .card-img-wrapper + .card-body {
            padding-left: 0;
        }

        .card-title {
            margin: 0;
            font-size: bootstrap.$font-size-base;
        }

        .card-mobile-title + .card-subtitle {
            margin: 0;
        }

        .card-body .card-title:not(.card-mobile-title),
        .card-body > .card-text,
        .card-footer {
            display: none;
        }
    }
}

@include bootstrap.media-breakpoint-up(md){

    .card-topic.hide-content-mobile .card-mobile-title {
        display: none;
    }
}
