@use 'abstracts/colors';
@use 'abstracts/functions';
@use 'vendors/bootstrap';


.listing-cards .fixed-content .card {
    margin-bottom: map-get(bootstrap.$spacers, 2);
}

.listing-cards.no-spacing .fixed-content .card {
    margin-bottom: 0;
    border-radius: 0;
}

@include bootstrap.media-breakpoint-down(md) {
    .listing-cards .card-standard.card-horizontal .card-layout-wrap {
        flex-basis: 100%;
    }
}